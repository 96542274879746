import { EntityEPCS } from "../Domain/Catalogos/EntityEPCS";
import { EntityMedidaPreventivas } from "../Domain/Catalogos/EntityMedidaPreventivas";
import { EntityOpcionAudio } from "../Domain/Catalogos/EntityOpcionAudio";
import { EntityPreguntaLA } from "../Domain/Catalogos/EntityPreguntaLA";
import { EntityTipoOperacion } from "../Domain/Catalogos/EntityTipoOperacion";
import { EntityTipoPregunta } from "../Domain/Catalogos/EntityTipoPregunta";
import { EntityFormatos } from "../Domain/CatalogosV2/EntityFormatos";
import { EntityDataAccess, EntityLoadMasterFromApp } from "../Domain/EntityDataAccess";
import { EntityDataUsuario } from "../Domain/EntityDataUsuario";
import { EntityEmpresa } from "../Domain/EntityEmpresa";
import { EntityInformationDataInitial } from "../Domain/EntityInformationDataInitial";
import { EntityOTs } from "../Domain/EntityOTs";
import { EntityPersonal } from "../Domain/EntityPersonal";
import { AdapterData } from "./AdapterData";
import { AdapterGenerico } from "./AdapterGenerico";
import { AdapterLimitProccess } from "./AdapterLimitProccess";
import { AdapterStorage } from "./AdapterStorage";
import { LanguageTranslate } from "./LanguageTranslate";
import { RepositoryImplGenerico } from "./RepositoryImplGenerico";
import { addLoadingMaestros } from "./SliceGenerico";


export class AdapterLoadMaster extends RepositoryImplGenerico<any> {

    public initServicesCalled(){
        const SERVICESCALLED_NAME_FROM_LOCAL_STORAGE = "servicesCalleds";
        const COUNT_COLECTIONS_INDEXEDDB = 33;

        let { servicesCalleds }: { servicesCalleds: EntityInformationDataInitial | null } = AdapterStorage.get(SERVICESCALLED_NAME_FROM_LOCAL_STORAGE);
        
        // Validar si faltó inicializar
        if (servicesCalleds === null) {
            servicesCalleds = {
                Pais: { called: false, count: 0, date: '' },
                Proyecto: { called: false, count: 0, date: '' },
                Empresa: { called: false, count: 0, date: '' },
                Delegacion: { called: false, count: 0, date: '' },
                Personal: { called: false, count: 0, date: '' },
                Actividad: { called: false, count: 0, date: '' },
                FactorRiesgo: { called: false, count: 0, date: '' },
                TipoIncidente: { called: false, count: 0, date: '' },
                OT: { called: false, count: 0, date: '' },
                Area: { called: false, count: 0, date: '' },
                FormatoInspecciones: { called: false, count: 0, date: '' },
                TipoInspeccion: { called: false, count: 0, date: '' },
                AnomaliaInspeccion: { called: false, count: 0, date: '' },
                AutochequeoTipologia: { called: false, count: 0, date: '' },
                AutochequeoTitulos: { called: false, count: 0, date: '' },
                AutochequeoItems: { called: false, count: 0, date: '' },
                NotificacionesTerminos: { called: false, count: 0, date: '' },
                CTEPCS: { called: false, count: 0, date: '' },
                CTMedidaPreventivas: { called: false, count: 0, date: '' },
                CTOpcionAudio: { called: false, count: 0, date: '' },
                CTOpcionLA: { called: false, count: 0, date: '' },
                CTPreguntaLA: { called: false, count: 0, date: '' },
                CTTipoPregunta: { called: false, count: 0, date: '' },
                CTTipoRiesgo: { called: false, count: 0, date: '' },
                CTLiteEmpresa: { called: false, count: 0, date: '' },
                CTLiteOT: { called: false, count: 0, date: '' },
                CTLitePais: { called: false, count: 0, date: '' },
                CTLitePersonal: { called: false, count: 0, date: '' },

                // Módulo Items
                Autochequeo: { called: false, count: 0, date: '' },
                Notificacion: { called: false, count: 0, date: '' },

                // v2
                CT_v2_Formato: { called: false, count: 0, date: '' },
            };
            AdapterStorage.set(SERVICESCALLED_NAME_FROM_LOCAL_STORAGE, servicesCalleds);
        }

        // Validar si faltan colecciones pendientes - ejemp: pueden ser nuevas colecciones
        if (Object.values(servicesCalleds).length <= COUNT_COLECTIONS_INDEXEDDB) {
            if (!servicesCalleds.CTLiteEmpresa) servicesCalleds.CTLiteEmpresa = { called: false, count: 0, date: '' };
            if (!servicesCalleds.CTLiteOT) servicesCalleds.CTLiteOT = { called: false, count: 0, date: '' };
            if (!servicesCalleds.CTLitePais) servicesCalleds.CTLitePais = { called: false, count: 0, date: '' };
            if (!servicesCalleds.CTLitePersonal) servicesCalleds.CTLitePersonal = { called: false, count: 0, date: '' };

            if (!servicesCalleds.Autochequeo) servicesCalleds.Autochequeo = { called: false, count: 0, date: '' };
            if (!servicesCalleds.Notificacion) servicesCalleds.Notificacion = { called: false, count: 0, date: '' };

            if (!servicesCalleds.CT_v2_Formato) servicesCalleds.CT_v2_Formato = { called: false, count: 0, date: '' };

            AdapterStorage.set(SERVICESCALLED_NAME_FROM_LOCAL_STORAGE, servicesCalleds);
        }
        
        servicesCalleds.Pais.date = !!servicesCalleds.Pais.date ? new Date(`${servicesCalleds.Pais.date}`) : new Date();
        servicesCalleds.Proyecto.date = !!servicesCalleds.Proyecto.date ? new Date(`${servicesCalleds.Proyecto.date}`) : new Date();
        servicesCalleds.Empresa.date = !!servicesCalleds.Empresa.date ? new Date(`${servicesCalleds.Empresa.date}`) : new Date();
        servicesCalleds.Delegacion.date = !!servicesCalleds.Delegacion.date ? new Date(`${servicesCalleds.Delegacion.date}`) : new Date();
        servicesCalleds.Personal.date = !!servicesCalleds.Personal.date ? new Date(`${servicesCalleds.Personal.date}`) : new Date();
        servicesCalleds.Actividad.date = !!servicesCalleds.Actividad.date ? new Date(`${servicesCalleds.Actividad.date}`) : new Date();
        servicesCalleds.FactorRiesgo.date = !!servicesCalleds.FactorRiesgo.date ? new Date(`${servicesCalleds.FactorRiesgo.date}`) : new Date();
        servicesCalleds.TipoIncidente.date = !!servicesCalleds.TipoIncidente.date ? new Date(`${servicesCalleds.TipoIncidente.date}`) : new Date();
        servicesCalleds.OT.date = !!servicesCalleds.OT.date ? new Date(`${servicesCalleds.OT.date}`) : new Date();
        servicesCalleds.Area.date = !!servicesCalleds.Area.date ? new Date(`${servicesCalleds.Area.date}`) : new Date();
        servicesCalleds.FormatoInspecciones.date = !!servicesCalleds.FormatoInspecciones.date ? new Date(`${servicesCalleds.FormatoInspecciones.date}`) : new Date();
        servicesCalleds.TipoInspeccion.date = !!servicesCalleds.TipoInspeccion.date ? new Date(`${servicesCalleds.TipoInspeccion.date}`) : new Date();
        servicesCalleds.AnomaliaInspeccion.date = !!servicesCalleds.AnomaliaInspeccion.date ? new Date(`${servicesCalleds.AnomaliaInspeccion.date}`) : new Date();
        servicesCalleds.AutochequeoTipologia.date = !!servicesCalleds.AutochequeoTipologia.date ? new Date(`${servicesCalleds.AutochequeoTipologia.date}`) : new Date();
        servicesCalleds.AutochequeoTitulos.date = !!servicesCalleds.AutochequeoTitulos.date ? new Date(`${servicesCalleds.AutochequeoTitulos.date}`) : new Date();
        servicesCalleds.AutochequeoItems.date = !!servicesCalleds.AutochequeoItems.date ? new Date(`${servicesCalleds.AutochequeoItems.date}`) : new Date();
        servicesCalleds.NotificacionesTerminos.date = !!servicesCalleds.NotificacionesTerminos.date ? new Date(`${servicesCalleds.NotificacionesTerminos.date}`) : new Date();
        servicesCalleds.CTEPCS.date = !!servicesCalleds.CTEPCS.date ? new Date(`${servicesCalleds.CTEPCS.date}`) : new Date();
        servicesCalleds.CTMedidaPreventivas.date = !!servicesCalleds.CTMedidaPreventivas.date ? new Date(`${servicesCalleds.CTMedidaPreventivas.date}`) : new Date();
        servicesCalleds.CTOpcionAudio.date = !!servicesCalleds.CTOpcionAudio.date ? new Date(`${servicesCalleds.CTOpcionAudio.date}`) : new Date();
        servicesCalleds.CTOpcionLA.date = !!servicesCalleds.CTOpcionLA.date ? new Date(`${servicesCalleds.CTOpcionLA.date}`) : new Date();
        servicesCalleds.CTPreguntaLA.date = !!servicesCalleds.CTPreguntaLA.date ? new Date(`${servicesCalleds.CTPreguntaLA.date}`) : new Date();
        servicesCalleds.CTTipoPregunta.date = !!servicesCalleds.CTTipoPregunta.date ? new Date(`${servicesCalleds.CTTipoPregunta.date}`) : new Date();
        servicesCalleds.CTTipoRiesgo.date = !!servicesCalleds.CTTipoRiesgo.date ? new Date(`${servicesCalleds.CTTipoRiesgo.date}`) : new Date();

        servicesCalleds.Autochequeo.date = !!servicesCalleds.Autochequeo.date ? new Date(`${servicesCalleds.Autochequeo.date}`) : new Date();
        servicesCalleds.Notificacion.date = !!servicesCalleds.Notificacion.date ? new Date(`${servicesCalleds.Notificacion.date}`) : new Date();

        servicesCalleds.CT_v2_Formato.date = !!servicesCalleds.CT_v2_Formato.date ? new Date(`${servicesCalleds.CT_v2_Formato.date}`) : new Date();

        return servicesCalleds;
    }

    public async loadMasterFromLogin(params: EntityDataAccess, information: EntityInformationDataInitial): Promise<void> {
        await Promise.all([
            this.selectPais(params, information, 'All'),
            this.selectEmpresas(params, information, 'All'),
            this.selectAutochequeoTipologia(params, information, 'All'),
            this.selectAutochequeoTitulos(params, information, 'All'),
            this.selectAutochequeoItems(params, information, 'All'),
            this.selectDelegacion(params, information, 'All'),
            this.selectOT(params, information, 'All'),
            this.selectPersonal(params, information, 'All'),
            this.selectCTOpcionAudio(params, information, 'All'),
            this.selectActividad(params, information, 'All'),
            this.selectProyecto(params, information, 'All'),
            this.selectFormatoInspecciones(params, information, 'All'),
            this.selectTipoInspeccion(params, information, 'All'),
            this.selectArea(params, information, 'All'),
            this.selectAPPV2Formatos(params, information, 'All'),
        ])
    }

    public async loadMasterFromApp(params: EntityLoadMasterFromApp, information: EntityInformationDataInitial): Promise<void> {
        await Promise.all([
            this.selectPais(params, information, 'Update'),
            // this.selectProyecto(params, information, 'Update'),
            this.selectEmpresas(params, information, 'Update'),
            this.selectPersonal(params, information, 'Update'),
            this.selectActividad(params, information, 'Update'),
            this.selectTipoIncidente(params, information, 'Update'),
            this.selectFactorRiesgo(params, information, 'Update'),
            this.selectOT(params, information, ((params.diferencialNewOT && params.diferencialNewOT.length > 0) || (params.diferencialDeleteOT && params.diferencialDeleteOT.length > 0)) ? 'Diferencial' :'Update'),
            this.selectArea(params, information, 'Update'),
            this.selectFormatoInspecciones(params, information, 'Update'),
            this.selectTipoInspeccion(params, information, 'Update'),
            this.selectAnomaliaInspeccion(params, information, 'Update'),
            this.selectAutochequeoTipologia(params, information, 'Update'),
            this.selectAutochequeoTitulos(params, information, 'Update'),
            this.selectAutochequeoItems(params, information, 'Update'),
            this.selectAPPV2Formatos(params, information, 'Update')
            // this.selectNotificationTerminos(params, information, 'Update'),
            // this.selectCTEPCS(params, information, 'Update'),
            // this.selectCTMedidaPreventivas(params, information, 'Update'),
            // this.selectCTOpcionAudio(params, information, 'Update'),
            // this.selectCTOpcionLA(params, information, 'Update'),
            // this.selectCTPreguntaLA(params, information, 'Update'),
            // this.selectCTTipoPregunta(params, information, 'Update'),
            // this.selectCTTipoRiesgo(params, information, 'Update'),
        ])
    }

    public async loadMasterOneTimeForWeek(params: EntityDataAccess, information: EntityInformationDataInitial, textReplace?: string): Promise<void> {

        await AdapterLimitProccess.exec([
            () => this.selectPais(params, information, 'All'),
            () => this.selectProyecto(params, information, 'All'),
            () => this.selectEmpresas(params, information, 'All'),
            () => this.selectPersonal(params, information, 'All'),
            () => this.selectActividad(params, information, 'All'),
            () => this.selectTipoIncidente(params, information, 'All'),
            () => this.selectFactorRiesgo(params, information, 'All'),
            () => this.selectOT(params, information, 'All'),
            () => this.selectArea(params, information, 'All'),
            () => this.selectFormatoInspecciones(params, information, 'All'),
            () => this.selectTipoInspeccion(params, information, 'All'),
            () => this.selectAnomaliaInspeccion(params, information, 'All'),
            () => this.selectAutochequeoTipologia(params, information, 'All'),
            () => this.selectAutochequeoTitulos(params, information, 'All'),
            () => this.selectAutochequeoItems(params, information, 'All'),
            () => this.selectNotificationTerminos(params, information, 'All'),
            () => this.selectCTEPCS(params, information, 'All'),
            () => this.selectCTMedidaPreventivas(params, information, 'All'),
            () => this.selectCTOpcionAudio(params, information, 'All'),
            () => this.selectCTOpcionLA(params, information, 'All'),
            () => this.selectCTPreguntaLA(params, information, 'All'),
            () => this.selectCTTipoPregunta(params, information, 'All'),
            () => this.selectCTTipoRiesgo(params, information, 'All'),
        ], 4, (text: string) => this.dispatch(addLoadingMaestros({ textLoadingMaestro: `${textReplace || `${LanguageTranslate().textoSincronizacionSemanalCatalogo}:`} <br /> ${text}` })))
    }

    public async loadMasterFromVariable(params: EntityDataAccess, information: EntityInformationDataInitial): Promise<void> {
        await Promise.all([
            this.selectPais(params, information, 'All'),
            this.selectProyecto(params, information, 'All'),
            this.selectEmpresas(params, information, 'All'),
            this.selectDelegacion(params, information, 'All'),
            this.selectPersonal(params, information, 'All', true),
            this.selectActividad(params, information, 'All'),
            this.selectFactorRiesgo(params, information, 'All'),
            this.selectTipoIncidente(params, information, 'All'),
            this.selectOT(params, information, 'All', true),
            this.selectArea(params, information, 'All'),
            this.selectFormatoInspecciones(params, information, 'All'),
            this.selectTipoInspeccion(params, information, 'All'),
            this.selectAnomaliaInspeccion(params, information, 'All'),
            this.selectAutochequeoTipologia(params, information, 'All'),
            this.selectAutochequeoTitulos(params, information, 'All'),
            this.selectAutochequeoItems(params, information, 'All', true),
            this.selectNotificationTerminos(params, information, 'All'),
        ])
    }

    public async loadMasterFromVariableLimit(params: EntityDataAccess, information: EntityInformationDataInitial, limitProccess: number): Promise<void> {
        await AdapterLimitProccess.exec(
            [
                () => this.selectPais(params, information, 'All'),
                () => this.selectProyecto(params, information, 'All'),
                () => this.selectEmpresas(params, information, 'All'),
                () => this.selectDelegacion(params, information, 'All'),
                () => this.selectPersonal(params, information, 'All', true),
                () => this.selectActividad(params, information, 'All'),
                () => this.selectFactorRiesgo(params, information, 'All'),
                () => this.selectTipoIncidente(params, information, 'All'),
                () => this.selectOT(params, information, 'All', true),
                () => this.selectArea(params, information, 'All'),
                () => this.selectFormatoInspecciones(params, information, 'All'),
                () => this.selectTipoInspeccion(params, information, 'All'),
                () => this.selectAnomaliaInspeccion(params, information, 'All'),
                () => this.selectAutochequeoTipologia(params, information, 'All'),
                () => this.selectAutochequeoTitulos(params, information, 'All'),
                () => this.selectAutochequeoItems(params, information, 'All', true),
                () => this.selectNotificationTerminos(params, information, 'All')
            ], limitProccess
        )
    }

    public async verifyLoadMasterFromInspecciones(params: EntityDataAccess, textReplace?: string) {
        const SERVICESCALLED_NAME_FROM_LOCAL_STORAGE = "servicesCalleds";
        let { servicesCalleds }: { servicesCalleds: EntityInformationDataInitial } = AdapterStorage.get(SERVICESCALLED_NAME_FROM_LOCAL_STORAGE)
        const listNoLoaded: Array<{ name: string; exec: Function; }> = [];
        const result: Array<number> = await this.dbLocal.countStore(['Empresa', 'Area', 'Actividad', 'FormatoInspecciones', 'TipoInspeccion', 'AnomaliaInspeccion']);
        const numberEmpty = 0;

        if (AdapterData.ot.length === numberEmpty) listNoLoaded.push({ name: 'OT', exec: () => this.selectOT(params, servicesCalleds, 'All') });
        if (AdapterData.personal.length === numberEmpty) listNoLoaded.push({ name: 'Personal', exec: () => this.selectPersonal(params, servicesCalleds, 'All') });
        if (result[0] === numberEmpty) listNoLoaded.push({ name: 'Empresa', exec: () => this.selectEmpresas(params, servicesCalleds, 'All') });
        if (result[1] === numberEmpty) listNoLoaded.push({ name: 'Area', exec: () => this.selectArea(params, servicesCalleds, 'All') });
        if (result[2] === numberEmpty) listNoLoaded.push({ name: 'Actividad', exec: () => this.selectActividad(params, servicesCalleds, 'All') });
        if (result[3] === numberEmpty) listNoLoaded.push({ name: 'Formato Inspecciones', exec: () => this.selectFormatoInspecciones(params, servicesCalleds, 'All') });
        if (result[4] === numberEmpty) listNoLoaded.push({ name: 'Tipo Inspección', exec: () => this.selectTipoInspeccion(params, servicesCalleds, 'All') });
        if (result[5] === numberEmpty) listNoLoaded.push({ name: 'Anomalía Inspección', exec: () => this.selectAnomaliaInspeccion(params, servicesCalleds, 'All') });
        
        if (listNoLoaded.length > 0) {
           // const result = await AdapterGenerico.createMessage(LanguageTranslate().textoAlerta, `No cuenta con información en estos catálogos necesarios para Inspecciones <br/> ${listNoLoaded.map(row => row.name).join(', ')}, <br/> ¿Desea Cargarlo?`, 'warning', true);
           // if (!result) return;
           // this.dispatch(addLoadingMaestros({ textLoadingMaestro: LanguageTranslate().textoSincronizacionSemanalCatalogo }));
           // this.dispatch(removeLoadingMaestros());
            await AdapterLimitProccess.exec(
                listNoLoaded.map(row => row.exec),
                4,
                (text: string) => this.dispatch(addLoadingMaestros({ textLoadingMaestro: `${textReplace || `${LanguageTranslate().textoSincronizacionSemanalCatalogo}:`} <br /> ${text}` }))
            );
            AdapterStorage.set('servicesCalleds', servicesCalleds);
        }
    }

    public async verifyLoadMasterFromRDI(params: EntityDataAccess, textReplace?: string) {
        const SERVICESCALLED_NAME_FROM_LOCAL_STORAGE = "servicesCalleds";
        let { servicesCalleds }: { servicesCalleds: EntityInformationDataInitial } = AdapterStorage.get(SERVICESCALLED_NAME_FROM_LOCAL_STORAGE)
        const listNoLoaded: Array<{ name: string; exec: Function; }> = [];
        const result: Array<number> = await this.dbLocal.countStore(['Pais', 'Delegacion', 'Empresa', 'Actividad', 'TipoIncidente', 'FactorRiesgo']);
        const numberEmpty = 0;

        if (AdapterData.ot.length === numberEmpty) listNoLoaded.push({ name: 'OT', exec: () => this.selectOT(params, servicesCalleds, 'All') });
        if (AdapterData.personal.length === numberEmpty) listNoLoaded.push({ name: 'Personal', exec: () => this.selectPersonal(params, servicesCalleds, 'All') });
        if (result[0] === numberEmpty) listNoLoaded.push({ name: 'Pais', exec: () => this.selectPais(params, servicesCalleds, 'All') });
        if (result[1] === numberEmpty) listNoLoaded.push({ name: 'Delegacion', exec: () => this.selectDelegacion(params, servicesCalleds, 'All') });
        if (result[2] === numberEmpty) listNoLoaded.push({ name: 'Empresa', exec: () => this.selectEmpresas(params, servicesCalleds, 'All') });
        if (result[3] === numberEmpty) listNoLoaded.push({ name: 'Actividad', exec: () => this.selectActividad(params, servicesCalleds, 'All') });
        if (result[4] === numberEmpty) listNoLoaded.push({ name: 'TipoIncidente', exec: () => this.selectTipoIncidente(params, servicesCalleds, 'All') });
        if (result[5] === numberEmpty) listNoLoaded.push({ name: 'FactorRiesgo', exec: () => this.selectFactorRiesgo(params, servicesCalleds, 'All') });
        
        if (listNoLoaded.length > 0) {
            await AdapterLimitProccess.exec(
                listNoLoaded.map(row => row.exec),
                4,
                (text: string) => this.dispatch(addLoadingMaestros({ textLoadingMaestro: `${textReplace || `${LanguageTranslate().textoSincronizacionSemanalCatalogo}:`} <br /> ${text}` }))
            );
            AdapterStorage.set('servicesCalleds', servicesCalleds);
        }
    }

    public async verifyLoadMasterFromAUT(params: EntityDataAccess, textReplace?: string) {
        const SERVICESCALLED_NAME_FROM_LOCAL_STORAGE = "servicesCalleds";
        let { servicesCalleds }: { servicesCalleds: EntityInformationDataInitial } = AdapterStorage.get(SERVICESCALLED_NAME_FROM_LOCAL_STORAGE)
        const listNoLoaded: Array<{ name: string; exec: Function; }> = [];
        const result: Array<number> = await this.dbLocal.countStore(['Delegacion', 'Empresa', 'Actividad', 'AutochequeoTipologia', 'AutochequeoTitulos', 'AutochequeoItems', 'CTPreguntaLA', 'CTTipoPregunta', 'CTEPCS', 'CTMedidaPreventivas', 'CTTipoRiesgo', 'CTOpcionAudio']);
        const numberEmpty = 0;

        if (AdapterData.ot.length === numberEmpty) listNoLoaded.push({ name: 'OT', exec: () => this.selectOT(params, servicesCalleds, 'All') });
        if (AdapterData.personal.length === numberEmpty) listNoLoaded.push({ name: 'Personal', exec: () => this.selectPersonal(params, servicesCalleds, 'All') });
        if (result[0] === numberEmpty) listNoLoaded.push({ name: 'Delegacion', exec: () => this.selectDelegacion(params, servicesCalleds, 'All') });
        if (result[1] === numberEmpty) listNoLoaded.push({ name: 'Empresa', exec: () => this.selectEmpresas(params, servicesCalleds, 'All') });
        if (result[2] === numberEmpty) listNoLoaded.push({ name: 'Actividad', exec: () => this.selectActividad(params, servicesCalleds, 'All') });
        if (result[3] === numberEmpty) listNoLoaded.push({ name: 'AutochequeoTipologia', exec: () => this.selectAutochequeoTipologia(params, servicesCalleds, 'All') });
        if (result[4] === numberEmpty) listNoLoaded.push({ name: 'AutochequeoTitulos', exec: () => this.selectAutochequeoTitulos(params, servicesCalleds, 'All') });
        if (result[5] === numberEmpty) listNoLoaded.push({ name: 'AutochequeoItems', exec: () => this.selectAutochequeoItems(params, servicesCalleds, 'All') });
        if (result[6] === numberEmpty) listNoLoaded.push({ name: 'CTPreguntaLA', exec: () => this.selectCTPreguntaLA(params, servicesCalleds, 'All') });
        if (result[7] === numberEmpty) listNoLoaded.push({ name: 'CTTipoPregunta', exec: () => this.selectCTTipoPregunta(params, servicesCalleds, 'All') });
        if (result[8] === numberEmpty) listNoLoaded.push({ name: 'CTEPCS', exec: () => this.selectCTEPCS(params, servicesCalleds, 'All') });
        if (result[9] === numberEmpty) listNoLoaded.push({ name: 'CTMedidaPreventivas', exec: () => this.selectCTMedidaPreventivas(params, servicesCalleds, 'All') });
        if (result[10] === numberEmpty) listNoLoaded.push({ name: 'CTTipoRiesgo', exec: () => this.selectCTTipoRiesgo(params, servicesCalleds, 'All') });
        if (result[11] === numberEmpty) listNoLoaded.push({ name: 'CTOpcionAudio', exec: () => this.selectCTOpcionAudio(params, servicesCalleds, 'All') });
        
        if (listNoLoaded.length > 0) {
            await AdapterLimitProccess.exec(
                listNoLoaded.map(row => row.exec),
                4,
                (text: string) => this.dispatch(addLoadingMaestros({ textLoadingMaestro: `${textReplace || `${LanguageTranslate().textoSincronizacionSemanalCatalogo}:`} <br /> ${text}` }))
            );
            AdapterStorage.set('servicesCalleds', servicesCalleds);
        }
    }

    public async selectEmpresas(params: EntityDataAccess, informationDataInitial: EntityInformationDataInitial, mode: 'All' | 'Update'): Promise<void> {
        let url = `${this.urlBase}/search/Empresas`;
        let entity: keyof EntityInformationDataInitial = 'Empresa';
        let Filtros = [
            {
                $match: {
                    "Pais.IdPais": {
                        [!params.pais.length ? '$nin' : '$in']: !params.pais.length ? [0] : params.pais 
                    },
                    "Estado.IdEstado": 1 ,
                    "Grupo.IdGrupo": {
                        [!params.grupo.length ? '$nin' : '$in']: !params.grupo.length ? [0] : params.grupo 
                    },
                    // "SincronizacionPNET.Estado": true
                }
            },
            {
                $project: { Estado: 0, Procesos: 0, Bitacora: 0 }
            }
        ]

        if (mode === 'All') {
            // Modo para recarga de variable
            await this.dbLocal.clearStore(entity);
            const body = { Filtros: JSON.stringify(Filtros) };
            await this.selectEntityREST(entity, informationDataInitial, url, body);
        } else {
            // Modo automático
            let count: number = await this.dbLocal.countStore(entity);
            let validData: boolean = informationDataInitial[entity] ? informationDataInitial[entity].date !== null ? AdapterGenerico.validarFecha(informationDataInitial[entity].date as string) : true : true;

            let _params: any;
            if (!informationDataInitial[entity] || !informationDataInitial[entity]?.called || count === 0) {
                // Si no cuenta con ninguna información, llama al servicio
                _params = { Filtros: JSON.stringify(Filtros) };
                await this.selectEntityREST(entity, informationDataInitial, url, _params, true);
            } else if (!validData) {
                // Solo llama una vez por día los campos a actualizar
                if (Filtros[0].$match) Filtros[0]["$match"]["Estado.IdEstado"] = { $nin: [] } as any;
                // if (Filtros[0].$match) Filtros[0]["$match"]["SincronizacionPNET.Estado"] = { $nin: [] } as any;
                if (Filtros[1].$project) Filtros[1]["$project"] = { Procesos: 0, Bitacora: 0 } as any;

                let fecha: Date = new Date(informationDataInitial[entity].date as string);
                let match: any = { $match: { $or: [{"DatosPnet.fechaSincronizacion": { $gte: fecha.toISOString() } }, { "Procesos.Registrar.Fecha": { $gte: fecha.toISOString() } }, { "Procesos.Modificar.Fecha": { $gte: fecha.toISOString() } }, { "Procesos.Eliminar.Fecha": { $gte: fecha.toISOString() } }] } };
                _params = { Filtros: JSON.stringify([match, ...Filtros]) };
                await this.selectEntityREST(entity, informationDataInitial, url, _params, true, 'Update');

                let data: EntityEmpresa[];
                try {
                    data = await this.service.bgCall<any>("POST", url, JSON.stringify(_params), "basic", "json", 'json', {}, 0);     
                    data = typeof data === 'string' ? JSON.parse(data) : (Array.isArray(data) ? data : [data]);           
                } catch (error) {
                    console.error(error);
                    data = [];
                }

                const dataDeleted = data.filter(row => row.Estado?.IdEstado === 10) // || row.SincronizacionPNET?.Estado === false );
                const filterNew = data.filter(row => row.Estado?.IdEstado === 1) // && row.SincronizacionPNET?.Estado === true);
                await this.dbLocal.deleteByIndexStore(dataDeleted.map(row => ({ nameStore: entity, value: row._id })));
                await this.dbLocal.insertDataStore({ nameStore: entity, data: filterNew });
                const count = await this.dbLocal.countStore(entity)
                Object.assign(informationDataInitial, { [entity]: { called: true, count: count, date: new Date() } });
            }
        }
    }

    public async selectAutochequeoTipologia(params: EntityDataAccess, informationDataInitial: EntityInformationDataInitial, mode: 'All' | 'Update'): Promise<void> {
        const url = `${this.urlBase}/search/Tipologias`;
        let entity: keyof EntityInformationDataInitial = 'AutochequeoTipologia';
        let Filtros = [{ $match: { "Pais.IdPais": { [!params.pais.length ? '$nin' : '$in']: !params.pais.length ? [0] : params.pais }, "Estado.IdEstado": 1, "Grupo.IdGrupo": { [!params.grupo.length ? '$nin' : '$in']: !params.grupo.length ? [0] : params.grupo } } }];
        const projectMDB = { $project: { _id: 1, Orden: 1, IdTipologia: "$IdTipología", TipologiaTrabajo: "$TipologíaTrabajo", Momento: 1, CodigoTipologia: 1, FamiliaTipologia: 1, RiesgosEspeciales: { $ifNull: ["$RiesgosEspeciales", 99] }, InformacionTipologia: 1, IdPais: "$Pais.IdPais", IdDelegacion: "$Delegacion.IdDelegacion", IdActividad: "$Actividad.IdActividad" } };

        if (mode === 'All') {
            await this.dbLocal.clearStore(entity);
            const body = { Filtros: JSON.stringify([...Filtros, projectMDB]) };
            await this.selectEntityREST(entity, informationDataInitial, url, body);
        } else {
            // Modo automático
            let count: number = await this.dbLocal.countStore(entity);
            let validData: boolean = informationDataInitial[entity] ? informationDataInitial[entity].date !== null ? AdapterGenerico.validarFecha(informationDataInitial[entity].date as string) : true : true;

            let _params: any;
            if (!informationDataInitial[entity] || !informationDataInitial[entity]?.called || count === 0) {
                // Si no cuenta con ninguna información, llama al servicio
                _params = { Filtros: JSON.stringify([...Filtros, projectMDB ]) };
                await this.selectEntityREST(entity, informationDataInitial, url, _params, true);
            } else if (!validData) {
                // Solo llama una vez por día los campos a actualizar
                let fecha: Date = new Date(informationDataInitial[entity].date as string);
                let match: any = { $match: { $or: [{ "Procesos.Registrar.Fecha": { $gt: fecha.toISOString() } }, { "Procesos.Modificar.Fecha": { $gt: fecha.toISOString() } }, { "Procesos.Eliminar.Fecha": { $gt: fecha.toISOString() } }] } };
                
                _params = { Filtros: JSON.stringify([match, ...Filtros, projectMDB ]) };
                await this.selectEntityREST(entity, informationDataInitial, url, _params, true, 'Update');
            }
        }
    }

    public async selectAutochequeoTitulos(params: EntityDataAccess, informationDataInitial: EntityInformationDataInitial, mode: 'All' | 'Update'): Promise<void> {
        let url = `${this.urlBase}/search/Titulos`;
        let entity: keyof EntityInformationDataInitial = 'AutochequeoTitulos';
        let projectMDB = { $project: { Procesos: 0, Estado: 0 } };
        let Filtros = [{ $match: { "Pais.IdPais": { [!params.pais.length ? '$nin' : '$in']: !params.pais.length ? [0] : params.pais }, "Estado.IdEstado": 1, "Grupo.IdGrupo": { [!params.grupo.length ? '$nin' : '$in']: !params.grupo.length ? [0] : params.grupo } } }, projectMDB];

        if (mode === 'All') {
            await this.dbLocal.clearStore(entity);
            const _params = { Filtros: JSON.stringify(Filtros) };
            await this.selectEntityREST(entity, informationDataInitial, url, _params);
        } else {
            // Modo automático
            let count: number = await this.dbLocal.countStore(entity);
            let validData: boolean = informationDataInitial[entity] ? informationDataInitial[entity].date !== null ? AdapterGenerico.validarFecha(informationDataInitial[entity].date as string) : true : true;

            let _params: any;
            if (!informationDataInitial[entity] || !informationDataInitial[entity]?.called || count === 0) {
                // Si no cuenta con ninguna información, llama al servicio
                _params = { Filtros: JSON.stringify([...Filtros ]) };
                await this.selectEntityREST(entity, informationDataInitial, url, _params, true);
            } else if (!validData) {
                // Solo llama una vez por día los campos a actualizar
                let fecha: Date = new Date(informationDataInitial[entity].date as string);
                let match: any = { $match: { $or: [{ "Procesos.Registrar.Fecha": { $gt: fecha.toISOString() } }, { "Procesos.Modificar.Fecha": { $gt: fecha.toISOString() } }, { "Procesos.Eliminar.Fecha": { $gt: fecha.toISOString() } }] } };
                
                _params = { Filtros: JSON.stringify([match, ...Filtros ]) };
                await this.selectEntityREST(entity, informationDataInitial, url, _params, true, 'Update');
            }
        }
    }

    public async selectAutochequeoItems(params: EntityDataAccess, informationDataInitial: EntityInformationDataInitial, mode: 'All' | 'Update', force: boolean = false): Promise<void> {
        let url = `${this.urlBase}/search/Items2`;
        let urlUpdate = `${this.urlBase}/search/Items`;
        let entity: keyof EntityInformationDataInitial = 'AutochequeoItems';
        const projectMDB = { $project: { _id: 1, IdRegistro: 1, Item: 1, Help: 1, Momento: 1, TipologiaArray: 1, Titulo: 1, Disponibilidad: 1, Estados: 1, VisibilidadPregunta: 1, OpcionesCierreCP: 1, IdPais: "$Pais.IdPais", IdDelegacion: "$Delegacion.IdDelegacion", IdActividad: "$Actividad.IdActividad" } }
        const Filtros = [{ $match: { "Pais.IdPais": { [!params.pais.length ? '$nin' : '$in']: !params.pais.length ? [0] : params.pais }, "Estado.IdEstado": 1, "Grupo.IdGrupo": { [!params.grupo.length ? '$nin' : '$in']: !params.grupo.length ? [0] : params.grupo } } }];

        if (mode === 'All') {
            await this.dbLocal.clearStore(entity);
            const body = { Filtros: JSON.stringify([...Filtros, projectMDB]), ...{ ...(force ? { force: true } : {}) } };
            await this.selectEntityREST(entity, informationDataInitial, force ? urlUpdate : url, body);
        } else {
            // Modo automático
            let count: number = await this.dbLocal.countStore(entity);
            let validData: boolean = informationDataInitial[entity] ? informationDataInitial[entity].date !== null ? AdapterGenerico.validarFecha(informationDataInitial[entity].date as string) : true : true;

            let _params: any;
            if (!informationDataInitial[entity] || !informationDataInitial[entity]?.called || count === 0) {
                // Si no cuenta con ninguna información, llama al servicio
                _params = { Filtros: JSON.stringify([ ...Filtros, projectMDB ]) };
                await this.selectEntityREST(entity, informationDataInitial, url, _params, true);
            } else if (!validData) {
                // Solo llama una vez por día los campos a actualizar
                let fecha: Date = new Date(informationDataInitial[entity].date as string);
                let match: any = { $match: { $or: [{ "Procesos.Registrar.Fecha": { $gt: fecha.toISOString() } }, { "Procesos.Modificar.Fecha": { $gt: fecha.toISOString() } }, { "Procesos.Eliminar.Fecha": { $gt: fecha.toISOString() } }] } };
                _params = { Filtros: JSON.stringify([match, ...Filtros, projectMDB ]), force: true };
                await this.selectEntityREST(entity, informationDataInitial, urlUpdate, _params, true, 'Update');
            }
        }
    }

    public async selectDelegacion(params: EntityDataAccess, informationDataInitial: EntityInformationDataInitial, mode: 'All' | 'Update'): Promise<void> {
        const url = `${this.urlBase}/search/Delegaciones`;
        let entity: keyof EntityInformationDataInitial = 'Delegacion';
        const Filtros = [
            {
                $match: {
                    "Estado.IdEstado": 1,
                    IdDelegacion: { [!params.delegacion.length ? '$nin' : '$in']: !params.delegacion.length ? [0] : params.delegacion },
                    "Grupo.IdGrupo": {
                        [!params.grupo.length ? '$nin' : '$in']: !params.grupo.length ? [0] : params.grupo 
                    }
                }
            },
            {
                $project: { Procesos: 0, Filial: 0, CodFilial: 0, InspeccionTC: 0, Estado: 0 }
            }
        ];

        if (mode === 'All') {
            // Modo para recarga de variable
            await this.dbLocal.clearStore(entity);
            const body = { Filtros: JSON.stringify(Filtros) };
            await this.selectEntityREST(entity, informationDataInitial, url, body);
        } else {
            // Modo automático
            let count: number = await this.dbLocal.countStore(entity);
            let validData: boolean = informationDataInitial[entity] ? informationDataInitial[entity].date !== null ? AdapterGenerico.validarFecha(informationDataInitial[entity].date as string) : true : true;

            let _params: any;
            if (!informationDataInitial[entity] || !informationDataInitial[entity]?.called || count === 0) {
                // Si no cuenta con ninguna información, llama al servicio
                _params = { Filtros: JSON.stringify(Filtros) };
                await this.selectEntityREST(entity, informationDataInitial, url, _params, true);
            } else if (!validData) {
                // Solo llama una vez por día los campos a actualizar
                let fecha: Date = new Date(informationDataInitial[entity].date as string);
                let match: any = { $match: { $or: [{ "Procesos.Registrar.Fecha": { $gt: fecha.toISOString() } }, { "Procesos.Modificar.Fecha": { $gt: fecha.toISOString() } }, { "Procesos.Eliminar.Fecha": { $gt: fecha.toISOString() } }] } };
                _params = { Filtros: JSON.stringify([match, ...Filtros]) };
                await this.selectEntityREST(entity, informationDataInitial, url, _params, true, 'Update');
            }
        }
    }

    public async selectPersonal(params: EntityDataAccess, informationDataInitial: EntityInformationDataInitial, mode: 'All' | 'Update', force: boolean = false): Promise<void> {
        const url = `${this.urlBase}/search/PersonalPWA2`;
        const urlUpdate = `${this.urlBase}/search/PersonalPWA`;
        const entity: keyof EntityInformationDataInitial = 'Personal';
        const Filtros = [
            {
                $match: {
                    "Estado.IdEstado": 1,
                    EmpresaHistorial: {
                        $elemMatch: {
                            "Pais.IdPais": { [!params.pais.length ? '$nin' : '$in']: !params.pais.length ? [0] : params.pais },
                            "Grupo.IdGrupo": {
                                [!params.grupo.length ? '$nin' : '$in']: !params.grupo.length ? [0] : params.grupo 
                            }
                        }
                    }
                }
            },
            {
                $project: { Procesos: 0 }
            }
        ]

        if (mode === 'All') {
            // Modo para recarga de variable
            await this.dbLocal.clearStore(entity);
            const body = { Query: JSON.stringify({ Filtros }), ...{ ...(force ? { force: true } : {}) } };
            let data = await this.selectEntityREST(entity, informationDataInitial, force ? urlUpdate : url, body, true);
            AdapterData.personal = typeof data === 'string' ? JSON.parse(data) : (Array.isArray(data) ? data : [data]);
        } else {
            // Modo automático
            let count: number = await this.dbLocal.countStore(entity);
            let validData: boolean = informationDataInitial[entity] ? informationDataInitial[entity].date !== null ? AdapterGenerico.validarFecha(informationDataInitial[entity].date as string) : true : true;

            let _params: any;
            if (!informationDataInitial[entity] || !informationDataInitial[entity]?.called || count === 0) {

                const body = { Query: JSON.stringify({ Filtros }), ...{ ...(force ? { force: true } : {}) } };
                let data = await this.selectEntityREST(entity, informationDataInitial, force ? urlUpdate : url, body, true);
                AdapterData.personal = typeof data === 'string' ? JSON.parse(data) : (Array.isArray(data) ? data : [data]);

            } else if (!validData) {
                let fecha: Date = new Date(informationDataInitial[entity].date as string);
                let match: any = { $match: {"Estado.IdEstado": 1, $or: [{ "Procesos.Registrar.Fecha": { $gt: fecha.toISOString() } }, { "Procesos.Modificar.Fecha": { $gt: fecha.toISOString() } }] } };
                _params = { Query: JSON.stringify({ Filtros: [match, ...Filtros] }), force: true };
                
                await this.selectEntityREST(entity, informationDataInitial, urlUpdate, _params, true, 'Update');
    
                let info: any[] = await this.dbLocal.selectAllStore(entity) || [];
                AdapterData.personal = info;
            } else {
                // Traer la lista de los eliminados y limpiar la BD local
                let fecha: Date = new Date(informationDataInitial[entity].date as string);
                let match: any = { $match: { "Estado.IdEstado": 10, $or: [{ "Procesos.Eliminar.Fecha": { $gt: fecha.toISOString() } }] } };
                _params = { Query: JSON.stringify({ Filtros: [match, ...Filtros] }), force: true };
                let data: EntityPersonal[];

                try {
                    data = await this.service.bgCall<any>("POST", urlUpdate, JSON.stringify(_params), "basic", "json", 'json', {}, 0);
                    const userNormal = data.filter(row => row.IdRol !== 1);
                    this.dbLocal.deleteByIndexStore(userNormal.map(row => ({ nameStore: entity, value: row.IdPersonal })));
                    const count = await this.dbLocal.countStore(entity)
                    Object.assign(informationDataInitial, { [entity]: { called: true, count: count, date: new Date() } });
                } catch (error) {}
    
                let info = await this.dbLocal.selectAllStore(entity);
                AdapterData.personal = info;
            }
        }
    }

    public async selectOT(params: EntityLoadMasterFromApp, informationDataInitial: EntityInformationDataInitial, mode: 'All' | 'Update' | 'Diferencial', force: boolean = false): Promise<void> {
        const url = `${this.urlBase}/search/OTs2`;
        const urlUpdate = `${this.urlBase}/search/OTs`;
        let entity: keyof EntityInformationDataInitial = 'OT';
        if (mode === 'Diferencial' && params.diferencialNewOT && params.diferencialDeleteOT) params.ot = [...params.diferencialDeleteOT, ...params.diferencialNewOT];

        const Filtros = [
            {
                $match: {
                    $or: [
                        {
                            "Estado.IdEstado": 1,
                            IdOT: { [!params.ot.length ? '$nin' : '$in']: !params.ot.length ? [0] : params.ot },
                            "Grupo.IdGrupo": {
                                [!params.grupo.length ? '$nin' : '$in']: !params.grupo.length ? [0] : params.grupo 
                            }
                        }
                    ]
                }
            },
            { $project: { Bitacora: 0, Estado: 0, IdEstadoBackup: 0, Procesos: 0, UpdateSUMA: 0, FEAPOOTT: 0, FELIOOTT: 0, Origen: 0 } }
        ]
        
        const { user }: { user: EntityDataUsuario } = AdapterStorage.get(['user']);
        if (user?.DatosRol?.Codigo === '04U') Filtros[0].$match?.$or.push({
            "Delegacion.IdDelegacion": { "$in": user.Delegacion.filter(row => row.todaDelegacion).map(row => row.IdDelegacion) },
            "Estado.IdEstado": 1,
            "Grupo.IdGrupo": {
                [!params.grupo.length ? '$nin' : '$in']: !params.grupo.length ? [0] : params.grupo 
            }
        } as any)

        if (mode === 'All') {
            await this.dbLocal.clearStore(entity);
            const body = { Filtros: JSON.stringify(Filtros), ...{ ...(force ? { force: true } : {}) } };
            let data = await this.selectEntityREST(entity, informationDataInitial, force ? urlUpdate : url , body, true);
            AdapterData.ot = typeof data === 'string' ? JSON.parse(data) : (Array.isArray(data) ? data : [data]);
        } else if (mode === 'Diferencial') {
            const body = { Filtros: JSON.stringify(Filtros), ...{ ...(force ? { force: true } : {}) } };
            const data: EntityOTs[] = await this.selectEntityREST(entity, informationDataInitial, force ? urlUpdate : url , body, true, mode);

            const dataDeleted = data.filter(row => params.diferencialDeleteOT?.includes(row.IdOT))
            const dataNew = data.filter(row => params.diferencialNewOT?.includes(row.IdOT))
            await this.dbLocal.deleteByIndexStore(dataDeleted.map(row => ({ nameStore: entity, value: row._id })));
            await this.dbLocal.insertDataStore({ nameStore: entity, data: dataNew });

            let info = await this.dbLocal.selectAllStore(entity);
            AdapterData.ot = info;
        } else {
            // Modo automático
            let count: number = await this.dbLocal.countStore(entity);
            let validDataPerDay: boolean = informationDataInitial[entity] ? informationDataInitial[entity].date !== null ? AdapterGenerico.validarFecha(informationDataInitial[entity].date as string) : true : true;

            let _params: any;
            if (!informationDataInitial[entity] || !informationDataInitial[entity]?.called || count === 0) {
                // Si no cuenta con ninguna información, llama al servicio
                _params = { Filtros: JSON.stringify(Filtros) };
                let data = await this.selectEntityREST(entity, informationDataInitial, force ? urlUpdate : url, _params, true);
                AdapterData.ot = typeof data === 'string' ? JSON.parse(data) : (Array.isArray(data) ? data : [data]);
            } else {
                //- Solo llama una vez por día los campos a actualizar
                let fecha: Date = new Date(informationDataInitial[entity].date as string);
                let match: any = { $match: { $or: [{ "Procesos.Registrar.IdFecha": { $gte: AdapterGenerico.getIdFechaGlobal(fecha) } }, { "Procesos.Modificar.IdFecha": { $gte: AdapterGenerico.getIdFechaGlobal(fecha) } }, { "Procesos.Eliminar.IdFecha": { $gte: AdapterGenerico.getIdFechaGlobal(fecha) } }] } };

                match["$match"] = {
                    ...match["$match"],
                    ...Filtros[0]["$match"],
                    "$or": [
                        ...match["$match"]["$or"] || [],
                        ...(Filtros[0]["$match"] as any)["$or"] || [],
                    ],
                    "Estado.IdEstado": { $in: [1, 10] } 
                }
                const _filtros = [match];

                // limite por 25 registros por día, ya que no se puede buscar por fecha, y solo con el ID
                if (validDataPerDay) {
                    _filtros.push({ $sort: { _id: -1 } })
                    _filtros.push({ $limit: 25 })
                }

                _params = { Filtros: JSON.stringify(_filtros), force: true };
                

                let data: EntityOTs[];
                try {
                    data = await this.service.bgCall<any>("POST", urlUpdate, JSON.stringify(_params), "basic", "json", 'json', {}, 0);     
                    data = typeof data === 'string' ? JSON.parse(data) : (Array.isArray(data) ? data : [data]);           
                } catch (error) {
                    console.error(error);
                    data = [];
                }
    
                const dataDeleted = data.filter(row => row.Estado.IdEstado === 10);
                const filterNew = data.filter(row => row.Estado.IdEstado === 1);
                await this.dbLocal.deleteByIndexStore(dataDeleted.map(row => ({ nameStore: entity, value: row._id })));
                await this.dbLocal.insertDataStore({ nameStore: entity, data: filterNew });
                const count = await this.dbLocal.countStore(entity)
                Object.assign(informationDataInitial, { [entity]: { called: true, count: count, date: new Date() } });
    
                let info = await this.dbLocal.selectAllStore(entity);
                AdapterData.ot = info;
                
            } /* else {
                // Para rellenar los inputs de OT
                let info = await this.dbLocal.selectAllStore(entity);
                AdapterData.ot = info;
            }*/
        }
    }

    public async selectActividad(params: EntityDataAccess, informationDataInitial: EntityInformationDataInitial, mode: 'All' | 'Update'): Promise<void> {
        let url: string = `${this.urlBase}/search/Actividad`;
        let entity: keyof EntityInformationDataInitial = 'Actividad';
        let Filtros = [{ $match: { "Estado.IdEstado": 1 } }, { $project: { Procesos: 0, Estado: 0, Bitacora: 0, Datos: 0 } }];
        
        if (mode === 'All') {
            // Modo para recarga de variable
            await this.dbLocal.clearStore(entity);
            let body = { Query: JSON.stringify({ Filtros }) };
            await this.selectEntityREST(entity, informationDataInitial, url, body, true);
        } else {
            // Modo automático
            let count: number = await this.dbLocal.countStore(entity);
            let validData: boolean = informationDataInitial[entity] ? informationDataInitial[entity].date !== null ? AdapterGenerico.validarFecha(informationDataInitial[entity].date as string) : true : true;
    
            let _params: any;
            if (!informationDataInitial[entity] || !informationDataInitial[entity]?.called || count === 0) {
                // Si no cuenta con ninguna información, llama al servicio
                _params = { Query: JSON.stringify({ Filtros }) };
                await this.selectEntityREST(entity, informationDataInitial, url, _params, true);
            } else if (!validData) {
                // Solo llama una vez por día los campos a actualizar
                let fecha: Date = new Date(informationDataInitial[entity].date as string);
                let match: any = { $match: { $or: [{ "Procesos.Registrar.Fecha": { $gt: fecha.toISOString() } }, { "Procesos.Modificar.Fecha": { $gt: fecha.toISOString() } }, { "Procesos.Eliminar.Fecha": { $gt: fecha.toISOString() } }] } };
                _params = { Query: JSON.stringify({ Filtros: [match, ...Filtros] }) };
                await this.selectEntityREST(entity, informationDataInitial, url, _params, true, 'Update');
            }
        }
    }

    public async selectPais(params: EntityDataAccess, informationDataInitial: EntityInformationDataInitial, mode: 'All' | 'Update'): Promise<void> {
        let url: string = `${this.urlBase}/search/Pais`;
        let entity: keyof EntityInformationDataInitial = 'Pais';
        let Filtros: Array<any> = [{ $match: { "Estado.IdEstado": 1 } }, { $project: { Procesos: 0, Estado: 0 } }];

        if (mode === 'All') {
            // Modo para recarga de variable
            await this.dbLocal.clearStore(entity);
            let body = { Query: JSON.stringify({ Filtros }) };
            await this.selectEntityREST(entity, informationDataInitial, url, body, true);
        } else {
            // Modo automático
            let count: number = await this.dbLocal.countStore(entity);
            let validData: boolean = informationDataInitial[entity] ? informationDataInitial[entity].date !== null ? AdapterGenerico.validarFecha(informationDataInitial[entity].date as string) : true : true;
    
            let _params: any;
            if (!informationDataInitial[entity] || !informationDataInitial[entity]?.called || count === 0) {
                // Si no cuenta con ninguna información, llama al servicio
                _params = { Query: JSON.stringify({ Filtros }) };
                await this.selectEntityREST(entity, informationDataInitial, url, _params, true);
            } else if (!validData) {
                // Solo llama una vez por día los campos a actualizar
                let fecha: Date = new Date(informationDataInitial[entity].date as string);
                let match: any = { $match: { $or: [{ "Procesos.Registrar.Fecha": { $gt: fecha.toISOString() } }, { "Procesos.Modificar.Fecha": { $gt: fecha.toISOString() } }, { "Procesos.Eliminar.Fecha": { $gt: fecha.toISOString() } }] } };
                _params = { Query: JSON.stringify({ Filtros: [match, ...Filtros] }) };
                await this.selectEntityREST(entity, informationDataInitial, url, _params, true, 'Update');
            }
        }
    }

    public async selectProyecto(params: EntityDataAccess, informationDataInitial: EntityInformationDataInitial, mode: 'All' | 'Update'): Promise<void> {
        let url: string = `${this.urlBase}/search/Proyectos`;
        let entity: keyof EntityInformationDataInitial = 'Proyecto';
        let Filtros = [
            { $match: { "Estado.IdEstado": 1, "Grupo.IdGrupo": { [!params.grupo.length ? '$nin' : '$in']: !params.grupo.length ? [0] : params.grupo } }},
            { $project: { Procesos: 0, Estado: 0 } }
        ]

        if (mode === 'All') {
            // Modo para recarga de variable
            await this.dbLocal.clearStore(entity);
            let body = { Filtros: JSON.stringify(Filtros) };
            await this.selectEntityREST(entity, informationDataInitial, url, body, true);
        } else {
            // Modo automático
            let count: number = await this.dbLocal.countStore(entity);
            let validData: boolean = informationDataInitial[entity] ? informationDataInitial[entity].date !== null ? AdapterGenerico.validarFecha(informationDataInitial[entity].date as string) : true : true;
    
            let _params: any;
            if (!informationDataInitial[entity] || !informationDataInitial[entity]?.called || count === 0) {
                // Si no cuenta con ninguna información, llama al servicio
                _params = { Filtros: JSON.stringify(Filtros) };
                await this.selectEntityREST(entity, informationDataInitial, url, _params, true);
            } else if (!validData) {
                // Solo llama una vez por día los campos a actualizar
                let fecha: Date = new Date(informationDataInitial[entity].date as string);
                let match: any = { $match: { $or: [{ "Procesos.Registrar.Fecha": { $gt: fecha.toISOString() } }, { "Procesos.Modificar.Fecha": { $gt: fecha.toISOString() } }, { "Procesos.Eliminar.Fecha": { $gt: fecha.toISOString() } }] } };
                _params = { Filtros: JSON.stringify([match, ...Filtros]) };
                await this.selectEntityREST(entity, informationDataInitial, url, _params, true, 'Update');
            }
        }
    }

    public async selectTipoIncidente(params: EntityDataAccess, informationDataInitial: EntityInformationDataInitial, mode: 'All' | 'Update'): Promise<void> {
        let url: string = `${this.urlBase}/TipoIncidente/1`;
        let entity: keyof EntityInformationDataInitial = 'TipoIncidente';

        if (mode === 'All') {
            // Modo para recarga de variable
            await this.dbLocal.clearStore(entity);
            await this.selectEntityREST(entity, informationDataInitial, url, {}, true, '', 'GET');
        } else {
            // Modo automático
            let count: number = await this.dbLocal.countStore(entity);

            if (!informationDataInitial[entity] || !informationDataInitial[entity]?.called || count === 0) {
                // Si no cuenta con ninguna información, llama al servicio
                await this.selectEntityREST(entity, informationDataInitial, url, {}, true, '', 'GET');
            }
        }
    }

    public async selectFactorRiesgo(params: EntityDataAccess, informationDataInitial: EntityInformationDataInitial, mode: 'All' | 'Update'): Promise<void> {
        let url: string = `${this.urlBase}/FactorRiesgo`;
        let entity: keyof EntityInformationDataInitial = 'FactorRiesgo';
        let Filtros: Array<any> = [{ $project: { Procesos: 0, Estado: 0 } }];

        if (mode === 'All') {
            // Modo para recarga de variable
            await this.dbLocal.clearStore(entity);
            await this.selectEntityREST(entity, informationDataInitial, url, { Query: JSON.stringify(Filtros) }, true);
        } else {
            // Modo automático
            let count: number = await this.dbLocal.countStore(entity);

            if (!informationDataInitial[entity] || !informationDataInitial[entity]?.called || count === 0) {
                // Si no cuenta con ninguna información, llama al servicio
                await this.selectEntityREST(entity, informationDataInitial, url, { Query: JSON.stringify(Filtros) }, true);
            }
        }
    }

    public async selectArea(params: EntityDataAccess, informationDataInitial: EntityInformationDataInitial, mode: 'All' | 'Update'): Promise<void> {
        let url: string = `${this.urlBase}/search/Area`;
        let entity: keyof EntityInformationDataInitial = 'Area';
        let Filtros = [{ $match: { "Pais.IdPais": { $nin: [0] }, "Estado.IdEstado": 1, "Grupo.IdGrupo": { [!params.grupo.length ? '$nin' : '$in']: !params.grupo.length ? [0] : params.grupo } } }]
        const projectMDB = { $project: { _id: 1, Area: 1, Codigo: 1, Delegacion: 1, Descripcion: 1, Grupo: 1, IdArea: 1, Pais: 1 } };

        if (mode === 'All') {
            // Modo para recarga de variable
            await this.dbLocal.clearStore(entity);
            let body = { Filtros: JSON.stringify([ ...Filtros, projectMDB ]) };
            await this.selectEntityREST(entity, informationDataInitial, url, body, true);
        } else {
            // Modo automático
            let count: number = await this.dbLocal.countStore(entity);
            let validData: boolean = informationDataInitial[entity] ? informationDataInitial[entity].date !== null ? AdapterGenerico.validarFecha(informationDataInitial[entity].date as string) : true : true;
    
            let _params: any;
            if (!informationDataInitial[entity] || !informationDataInitial[entity]?.called || count === 0) {
                // Si no cuenta con ninguna información, llama al servicio
                _params = { Filtros: JSON.stringify([...Filtros, projectMDB]) };
                await this.selectEntityREST(entity, informationDataInitial, url, _params, true);
            } else if (!validData) {
                // Solo llama una vez por día los campos a actualizar
                let fecha: Date = new Date(informationDataInitial[entity].date as string);
                let match: any = { $match: { $or: [{ "Procesos.Registrar.Fecha": { $gt: fecha.toISOString() } }, { "Procesos.Modificar.Fecha": { $gt: fecha.toISOString() } }, { "Procesos.Eliminar.Fecha": { $gt: fecha.toISOString() } }] } };
                
                _params = { Filtros: JSON.stringify([match, ...Filtros, projectMDB]) };
                await this.selectEntityREST(entity, informationDataInitial, url, _params, true, 'Update');
            }
        }
    }

    public async selectFormatoInspecciones(params: EntityDataAccess, informationDataInitial: EntityInformationDataInitial, mode: 'All' | 'Update'): Promise<void> {
        let url: string = `${this.urlBase}/search/FormatoInspeccion`;
        let entity: keyof EntityInformationDataInitial = 'FormatoInspecciones';
        let Filtros = [{ $match: { "Estado.IdEstado": 1, "Grupo.IdGrupo": { [!params.grupo.length ? '$nin' : '$in']: !params.grupo.length ? [0] : params.grupo } } }]
        const projectMDB = { $project: { _id: 1, FormatoInspeccion: 1, Grupo: 1, IdFormatoInspeccion: 1, TipoOperacion: 1 } };

        if (mode === 'All') {
            // Modo para recarga de variable
            await this.dbLocal.clearStore(entity);
            let body = { Filtros: JSON.stringify([ ...Filtros, projectMDB ]) };
            await this.selectEntityREST(entity, informationDataInitial, url, body, true);
        } else {
            // Modo automático
            let count: number = await this.dbLocal.countStore(entity);
            let validData: boolean = informationDataInitial[entity] ? informationDataInitial[entity].date !== null ? AdapterGenerico.validarFecha(informationDataInitial[entity].date as string) : true : true;
    
            let _params: any;
            if (!informationDataInitial[entity] || !informationDataInitial[entity]?.called || count === 0) {
                // Si no cuenta con ninguna información, llama al servicio
                _params = { Filtros: JSON.stringify([...Filtros, projectMDB]) };
                await this.selectEntityREST(entity, informationDataInitial, url, _params, true);
            } else if (!validData) {
                // Solo llama una vez por día los campos a actualizar
                let fecha: Date = new Date(informationDataInitial[entity].date as string);
                let match: any = { $match: { $or: [{ "Procesos.Registrar.Fecha": { $gt: fecha.toISOString() } }, { "Procesos.Modificar.Fecha": { $gt: fecha.toISOString() } }, { "Procesos.Eliminar.Fecha": { $gt: fecha.toISOString() } }] } };
                
                _params = { Filtros: JSON.stringify([match, ...Filtros, projectMDB]) };
                await this.selectEntityREST(entity, informationDataInitial, url, _params, true, 'Update');
            }
        }
    }

    public async selectTipoInspeccion(params: EntityDataAccess, informationDataInitial: EntityInformationDataInitial, mode: 'All' | 'Update'): Promise<void> {
        let url: string = `${this.urlBase}/search/TipoInspeccion`;
        let entity: keyof EntityInformationDataInitial = 'TipoInspeccion';
        let Filtros = [{ $match: { "Estado.IdEstado": 1 } }, { $project: { Procesos: 0, Estado: 0 } }]

        if (mode === 'All') {
            // Modo para recarga de variable
            await this.dbLocal.clearStore(entity);
            let body = { Filtros: JSON.stringify([ ...Filtros ]) };
            await this.selectEntityREST(entity, informationDataInitial, url, body, true);
        } else {
            // Modo automático
            let count: number = await this.dbLocal.countStore(entity);
            let validData: boolean = informationDataInitial[entity] ? informationDataInitial[entity].date !== null ? AdapterGenerico.validarFecha(informationDataInitial[entity].date as string) : true : true;
    
            let _params: any;
            if (!informationDataInitial[entity] || !informationDataInitial[entity]?.called || count === 0) {
                // Si no cuenta con ninguna información, llama al servicio
                _params = { Filtros: JSON.stringify([...Filtros]) };
                await this.selectEntityREST(entity, informationDataInitial, url, _params, true);
            } else if (!validData) {
                // Solo llama una vez por día los campos a actualizar
                let fecha: Date = new Date(informationDataInitial[entity].date as string);
                let match: any = { $match: { $or: [{ "Procesos.Registrar.Fecha": { $gt: fecha.toISOString() } }, { "Procesos.Modificar.Fecha": { $gt: fecha.toISOString() } }, { "Procesos.Eliminar.Fecha": { $gt: fecha.toISOString() } }] } };
                
                _params = { Filtros: JSON.stringify([match, ...Filtros]) };
                await this.selectEntityREST(entity, informationDataInitial, url, _params, true, 'Update');
            }
        }
    }

    public async selectAnomaliaInspeccion(params: EntityDataAccess, informationDataInitial: EntityInformationDataInitial, mode: 'All' | 'Update'): Promise<void> {
        let url: string = `${this.urlBase}/search/TipoAnomalia`;
        let entity: keyof EntityInformationDataInitial = 'AnomaliaInspeccion';
        let Filtros = [
            { $match: { "Estado.IdEstado": 1, "Grupo.IdGrupo": { [!params.grupo.length ? '$nin' : '$in']: !params.grupo.length ? [0] : params.grupo } } },
            { $project: { Procesos: 0, Estado: 0, Bitacora: 0 } }
        ];

        if (mode === 'All') {
            // Modo para recarga de variable
            await this.dbLocal.clearStore(entity);
            let body = { Filtros: JSON.stringify([ ...Filtros ]) };
            await this.selectEntityREST(entity, informationDataInitial, url, body, true);
        } else {
            // Modo automático
            let count: number = await this.dbLocal.countStore(entity);
            let validData: boolean = informationDataInitial[entity] ? informationDataInitial[entity].date !== null ? AdapterGenerico.validarFecha(informationDataInitial[entity].date as string) : true : true;
    
            let _params: any;
            if (!informationDataInitial[entity] || !informationDataInitial[entity]?.called || count === 0) {
                // Si no cuenta con ninguna información, llama al servicio
                _params = { Filtros: JSON.stringify([...Filtros ]) };
                await this.selectEntityREST(entity, informationDataInitial, url, _params, true);
            } else if (!validData) {
                // Solo llama una vez por día los campos a actualizar
                let fecha: Date = new Date(informationDataInitial[entity].date as string);
                let match: any = { $match: { $or: [{ "Procesos.Registrar.Fecha": { $gt: fecha.toISOString() } }, { "Procesos.Modificar.Fecha": { $gt: fecha.toISOString() } }, { "Procesos.Eliminar.Fecha": { $gt: fecha.toISOString() } }] } };
                
                _params = { Filtros: JSON.stringify([match, ...Filtros ]) };
                await this.selectEntityREST(entity, informationDataInitial, url, _params, true, 'Update');
            }
        }
    }

    public async selectNotificationTerminos(params: EntityDataAccess, informationDataInitial: EntityInformationDataInitial, mode: 'All' | 'Update'): Promise<void> {
        let url: string = `${this.urlBase}/search/Notificaciones`;
        let entity: keyof EntityInformationDataInitial = 'NotificacionesTerminos';
        let Filtros = [{ $match: { "Estado.IdEstado": 1 } }];

         if (mode === 'All') {
            // Modo para recarga de variable
            await this.dbLocal.clearStore(entity);
            let body = { Filtros: JSON.stringify([ ...Filtros ]) };
            await this.selectEntityREST(entity, informationDataInitial, url, body, true);
        } else {
            // Modo automático
            let count: number = await this.dbLocal.countStore(entity);
            let validData: boolean = informationDataInitial[entity] ? informationDataInitial[entity].date !== null ? AdapterGenerico.validarFecha(informationDataInitial[entity].date as string) : true : true;
    
            let _params: any;
            if (!informationDataInitial[entity] || !informationDataInitial[entity]?.called || count === 0) {
                // Si no cuenta con ninguna información, llama al servicio
                _params = { Filtros: JSON.stringify([...Filtros ]) };
                await this.selectEntityREST(entity, informationDataInitial, url, _params, true);
            } else if (!validData) {
                // Solo llama una vez por día los campos a actualizar
                let fecha: Date = new Date(informationDataInitial[entity].date as string);
                let match: any = { $match: { $or: [{ "Procesos.Registrar.Fecha": { $gt: fecha.toISOString() } }, { "Procesos.Modificar.Fecha": { $gt: fecha.toISOString() } }, { "Procesos.Eliminar.Fecha": { $gt: fecha.toISOString() } }] } };
                
                _params = { Filtros: JSON.stringify([match, ...Filtros ]) };
                await this.selectEntityREST(entity, informationDataInitial, url, _params, true, 'Update');
            }
        }
    }

    public async selectCTEPCS(params: EntityDataAccess, informationDataInitial: EntityInformationDataInitial, mode: 'All' | 'Update'): Promise<void> {
        let url: string = `${this.urlBase}/LA/EPCS/Search`;
        let entity: keyof EntityInformationDataInitial = 'CTEPCS';
        let Filtros = [{ $project: { bitacora: 0, registrar: 0, eliminar: 0, actualizar: 0 } }];

        if (mode === 'All') {
            // Modo para recarga de variable
            await this.dbLocal.clearStore(entity);
            let body = { Filtros: JSON.stringify([ ...Filtros, { $match: { "estado": true } } ]) };
            await this.selectEntityREST(entity, informationDataInitial, url, body, true);
        } else {
            // Modo automático
            let count: number = await this.dbLocal.countStore(entity);
            let validData: boolean = informationDataInitial[entity] ? informationDataInitial[entity].date !== null ? AdapterGenerico.validarFecha(informationDataInitial[entity].date as string) : true : true;
    
            let _params: any;
            if (!informationDataInitial[entity] || !informationDataInitial[entity]?.called || count === 0) {
                // Si no cuenta con ninguna información, llama al servicio
                _params = { Filtros: JSON.stringify([ ...Filtros, { $match: { "estado": true } } ]) };
                await this.selectEntityREST(entity, informationDataInitial, url, _params, true);
            } else if (!validData) {
                // Solo llama una vez por día los campos a actualizar
                let fecha: Date = new Date(informationDataInitial[entity].date as string);
                let match: any = { $match: { $or: [{ "registrar.fecha": { $gt: fecha.toISOString() } }, { "actualizar.fecha": { $gt: fecha.toISOString() } }, { "eliminar.fecha": { $gt: fecha.toISOString() } }] } };
                
                _params = { Filtros: JSON.stringify([match, ...Filtros ]) };

                // Eliminar registros actualizados que fueron eliminados
                const result: EntityEPCS[] = await this.selectEntityREST(entity, informationDataInitial, url, _params, true, 'Update');
                await this.dbLocal.deleteByIndexStore(result.filter(x => !x.estado).map(x => ({ nameStore: 'CTEPCS', value: x._id })));
            }
        }
    }

    public async selectCTMedidaPreventivas(params: EntityDataAccess, informationDataInitial: EntityInformationDataInitial, mode: 'All' | 'Update'): Promise<void> {
        let url: string = `${this.urlBase}/LA/MedidadPreventivas/Search`;
        let entity: keyof EntityInformationDataInitial = 'CTMedidaPreventivas';
        let Filtros = [{ $project: { actualizar: 0, bitacora: 0, eliminar: 0, registrar: 0 } }];

         if (mode === 'All') {
            // Modo para recarga de variable
            await this.dbLocal.clearStore(entity);
            let body = { Filtros: JSON.stringify([ ...Filtros, { $match: { "estado": true } } ]) };
            await this.selectEntityREST(entity, informationDataInitial, url, body, true);
        } else {
            // Modo automático
            let count: number = await this.dbLocal.countStore(entity);
            let validData: boolean = informationDataInitial[entity] ? informationDataInitial[entity].date !== null ? AdapterGenerico.validarFecha(informationDataInitial[entity].date as string) : true : true;
    
            let _params: any;
            if (!informationDataInitial[entity] || !informationDataInitial[entity]?.called || count === 0) {
                // Si no cuenta con ninguna información, llama al servicio
                _params = { Filtros: JSON.stringify([ ...Filtros, { $match: { "estado": true } } ]) };
                await this.selectEntityREST(entity, informationDataInitial, url, _params, true);
            } else if (!validData) {
                // Solo llama una vez por día los campos a actualizar
                let fecha: Date = new Date(informationDataInitial[entity].date as string);
                let match: any = { $match: { $or: [{ "registrar.fecha": { $gt: fecha.toISOString() } }, { "actualizar.fecha": { $gt: fecha.toISOString() } }, { "eliminar.fecha": { $gt: fecha.toISOString() } }] } };
                
                _params = { Filtros: JSON.stringify([match, ...Filtros ]) };
                await this.selectEntityREST(entity, informationDataInitial, url, _params, true, 'Update');

                // Eliminar registros actualizados que fueron eliminados
                const result: EntityMedidaPreventivas[] = await this.selectEntityREST(entity, informationDataInitial, url, _params, true, 'Update');
                await this.dbLocal.deleteByIndexStore(result.filter(x => !x.estado).map(x => ({ nameStore: entity, value: x._id })));
            }
        }
    }

    public async selectCTOpcionAudio(params: EntityDataAccess, informationDataInitial: EntityInformationDataInitial, mode: 'All' | 'Update'): Promise<void> {
        let url: string = `${this.urlBase}/LA/OpcionAudio/Search`;
        let entity: keyof EntityInformationDataInitial = 'CTOpcionAudio';
        let Filtros = [{ $project: { actualizar: 0, bitacora: 0, eliminar: 0, registrar: 0 } }];

         if (mode === 'All') {
            // Modo para recarga de variable
            await this.dbLocal.clearStore(entity);
            let body = { Filtros: JSON.stringify([ ...Filtros, { $match: { "estado": true } } ]) };
            await this.selectEntityREST(entity, informationDataInitial, url, body, true);
        } else {
            // Modo automático
            let count: number = await this.dbLocal.countStore(entity);
            let validData: boolean = informationDataInitial[entity] ? informationDataInitial[entity].date !== null ? AdapterGenerico.validarFecha(informationDataInitial[entity].date as string) : true : true;
    
            let _params: any;
            if (!informationDataInitial[entity] || !informationDataInitial[entity]?.called || count === 0) {
                // Si no cuenta con ninguna información, llama al servicio
                _params = { Filtros: JSON.stringify([ ...Filtros, { $match: { "estado": true } } ]) };
                await this.selectEntityREST(entity, informationDataInitial, url, _params, true);
            } else if (!validData) {
                // Solo llama una vez por día los campos a actualizar
                let fecha: Date = new Date(informationDataInitial[entity].date as string);
                let match: any = { $match: { $or: [{ "registrar.fecha": { $gt: fecha.toISOString() } }, { "actualizar.fecha": { $gt: fecha.toISOString() } }, { "eliminar.fecha": { $gt: fecha.toISOString() } }] } };
                
                _params = { Filtros: JSON.stringify([match, ...Filtros ]) };
                await this.selectEntityREST(entity, informationDataInitial, url, _params, true, 'Update');

                // Eliminar registros actualizados que fueron eliminados
                const result: EntityOpcionAudio[] = await this.selectEntityREST(entity, informationDataInitial, url, _params, true, 'Update');
                await this.dbLocal.deleteByIndexStore(result.filter(x => !x.estado).map(x => ({ nameStore: entity, value: x._id })));
            }
        }
    }

    public async selectCTOpcionLA(params: EntityDataAccess, informationDataInitial: EntityInformationDataInitial, mode: 'All' | 'Update'): Promise<void> {
        let url: string = `${this.urlBase}/LA/OpcionPreguntas/Search`;
        let entity: keyof EntityInformationDataInitial = 'CTOpcionLA';
        let Filtros = [{ $project: { actualizar: 0, bitacora: 0, eliminar: 0, registrar: 0 } }];

         if (mode === 'All') {
            // Modo para recarga de variable
            await this.dbLocal.clearStore(entity);
            let body = { Filtros: JSON.stringify([ ...Filtros, { $match: { "estado": true } } ]) };
            await this.selectEntityREST(entity, informationDataInitial, url, body, true);
        } else {
            // Modo automático
            let count: number = await this.dbLocal.countStore(entity);
            let validData: boolean = informationDataInitial[entity] ? informationDataInitial[entity].date !== null ? AdapterGenerico.validarFecha(informationDataInitial[entity].date as string) : true : true;
    
            let _params: any;
            if (!informationDataInitial[entity] || !informationDataInitial[entity]?.called || count === 0) {
                // Si no cuenta con ninguna información, llama al servicio
                _params = { Filtros: JSON.stringify([ ...Filtros, { $match: { "estado": true } } ]) };
                await this.selectEntityREST(entity, informationDataInitial, url, _params, true);
            } else if (!validData) {
                // Solo llama una vez por día los campos a actualizar
                let fecha: Date = new Date(informationDataInitial[entity].date as string);
                let match: any = { $match: { $or: [{ "registrar.fecha": { $gt: fecha.toISOString() } }, { "actualizar.fecha": { $gt: fecha.toISOString() } }, { "eliminar.fecha": { $gt: fecha.toISOString() } }] } };
                
                _params = { Filtros: JSON.stringify([match, ...Filtros ]) };
                await this.selectEntityREST(entity, informationDataInitial, url, _params, true, 'Update');

                // Eliminar registros actualizados que fueron eliminados
                const result: EntityOpcionAudio[] = await this.selectEntityREST(entity, informationDataInitial, url, _params, true, 'Update');
                await this.dbLocal.deleteByIndexStore(result.filter(x => !x.estado).map(x => ({ nameStore: entity, value: x._id })));
            }
        }
    }

    public async selectCTPreguntaLA(params: EntityDataAccess, informationDataInitial: EntityInformationDataInitial, mode: 'All' | 'Update'): Promise<void> {
        let url: string = `${this.urlBase}/LA/Preguntas/Search`;
        let entity: keyof EntityInformationDataInitial = 'CTPreguntaLA';
        let Filtros = [{ $project: { actualizar: 0, bitacora: 0, eliminar: 0, registrar: 0 } }];

         if (mode === 'All') {
            // Modo para recarga de variable
            await this.dbLocal.clearStore(entity);
            let body = { Filtros: JSON.stringify([ ...Filtros, { $match: { "estado": true } } ]) };
            await this.selectEntityREST(entity, informationDataInitial, url, body, true);
        } else {
            // Modo automático
            let count: number = await this.dbLocal.countStore(entity);
            let validData: boolean = informationDataInitial[entity] ? informationDataInitial[entity].date !== null ? AdapterGenerico.validarFecha(informationDataInitial[entity].date as string) : true : true;
    
            let _params: any;
            if (!informationDataInitial[entity] || !informationDataInitial[entity]?.called || count === 0) {
                // Si no cuenta con ninguna información, llama al servicio
                _params = { Filtros: JSON.stringify([ ...Filtros, { $match: { "estado": true } } ]) };
                await this.selectEntityREST(entity, informationDataInitial, url, _params, true);
            } else if (!validData) {
                // Solo llama una vez por día los campos a actualizar
                let fecha: Date = new Date(informationDataInitial[entity].date as string);
                let match: any = { $match: { $or: [{ "registrar.fecha": { $gt: fecha.toISOString() } }, { "actualizar.fecha": { $gt: fecha.toISOString() } }, { "eliminar.fecha": { $gt: fecha.toISOString() } }] } };
                
                _params = { Filtros: JSON.stringify([match, ...Filtros ]) };
                
                // Eliminar registros actualizados que fueron eliminados
                const result: EntityPreguntaLA[] = await this.selectEntityREST(entity, informationDataInitial, url, _params, true, 'Update');
                await this.dbLocal.deleteByIndexStore(result.filter(x => !x.estado).map(x => ({ nameStore: entity, value: x._id })));
            }
        }
    }

    public async selectCTTipoPregunta(params: EntityDataAccess, informationDataInitial: EntityInformationDataInitial, mode: 'All' | 'Update'): Promise<void> {
        let url: string = `${this.urlBase}/LA/TipoPregunta/Search`;
        let entity: keyof EntityInformationDataInitial = 'CTTipoPregunta';
        let Filtros = [{ $project: { actualizar: 0, bitacora: 0, eliminar: 0, registrar: 0 } }];

         if (mode === 'All') {
            // Modo para recarga de variable
            await this.dbLocal.clearStore(entity);
            let body = { Filtros: JSON.stringify([ ...Filtros, { $match: { "estado": true } } ]) };
            await this.selectEntityREST(entity, informationDataInitial, url, body, true);
        } else {
            // Modo automático
            let count: number = await this.dbLocal.countStore(entity);
            let validData: boolean = informationDataInitial[entity] ? informationDataInitial[entity].date !== null ? AdapterGenerico.validarFecha(informationDataInitial[entity].date as string) : true : true;
    
            let _params: any;
            if (!informationDataInitial[entity] || !informationDataInitial[entity]?.called || count === 0) {
                // Si no cuenta con ninguna información, llama al servicio
                _params = { Filtros: JSON.stringify([...Filtros, { $match: { "estado": true } } ]) };
                await this.selectEntityREST(entity, informationDataInitial, url, _params, true);
            } else if (!validData) {
                // Solo llama una vez por día los campos a actualizar
                let fecha: Date = new Date(informationDataInitial[entity].date as string);
                let match: any = { $match: { $or: [{ "registrar.fecha": { $gt: fecha.toISOString() } }, { "actualizar.fecha": { $gt: fecha.toISOString() } }, { "eliminar.fecha": { $gt: fecha.toISOString() } }] } };
                
                _params = { Filtros: JSON.stringify([match, ...Filtros ]) };

                // Eliminar registros actualizados que fueron eliminados
                const result: EntityTipoPregunta[] = await this.selectEntityREST(entity, informationDataInitial, url, _params, true, 'Update');
                await this.dbLocal.deleteByIndexStore(result.filter(x => !x.estado).map(x => ({ nameStore: entity, value: x._id })));
            }
        }
    }

    public async selectCTTipoRiesgo(params: EntityDataAccess, informationDataInitial: EntityInformationDataInitial, mode: 'All' | 'Update'): Promise<void> {
        let url: string = `${this.urlBase}/LA/TipoRiesgo/Search`;
        let entity: keyof EntityInformationDataInitial = 'CTTipoRiesgo';
        let Filtros = [{ $project: { actualizar: 0, bitacora: 0, eliminar: 0, registrar: 0 } }];

         if (mode === 'All') {
            // Modo para recarga de variable
            await this.dbLocal.clearStore(entity);
            let body = { Filtros: JSON.stringify([ ...Filtros, { $match: { "estado": true } } ]) };
            await this.selectEntityREST(entity, informationDataInitial, url, body, true);
        } else {
            // Modo automático
            let count: number = await this.dbLocal.countStore(entity);
            let validData: boolean = informationDataInitial[entity] ? informationDataInitial[entity].date !== null ? AdapterGenerico.validarFecha(informationDataInitial[entity].date as string) : true : true;
    
            let _params: any;
            if (!informationDataInitial[entity] || !informationDataInitial[entity]?.called || count === 0) {
                // Si no cuenta con ninguna información, llama al servicio
                _params = { Filtros: JSON.stringify([...Filtros ]) };
                await this.selectEntityREST(entity, informationDataInitial, url, _params, true);
            } else if (!validData) {
                // Solo llama una vez por día los campos a actualizar
                let fecha: Date = new Date(informationDataInitial[entity].date as string);
                let match: any = { $match: { $or: [{ "registrar.fecha": { $gt: fecha.toISOString() } }, { "actualizar.fecha": { $gt: fecha.toISOString() } }, { "eliminar.fecha": { $gt: fecha.toISOString() } }] } };
                
                _params = { Filtros: JSON.stringify([match, ...Filtros ]) };
                await this.selectEntityREST(entity, informationDataInitial, url, _params, true, 'Update');
            }
        }
    }

    //! APP V2
    public async selectAPPV2Formatos(params: EntityDataAccess, informationDataInitial: EntityInformationDataInitial, mode: 'All' | 'Update'): Promise<void> {
        const url: string = `${this.urlBaseNewBackend}/Formato/Formato/findPermisos`;
        let entity: keyof EntityInformationDataInitial = 'CT_v2_Formato';
        const { user }: { user: EntityDataUsuario | null } = AdapterStorage.get(['user']);
        if (!user) return;

        let body = {
            "_idGrupo": "60495ac551df5d5ee466473a",
            "_idPais": "60495e4f51df5d5ee4664742",
            "identificacion": user.Identificacion,
        };

        if (mode === 'All') {
            // Modo para recarga de variable
            await this.dbLocal.clearStore(entity);
            let result = await this.service.call<any>("POST", url, JSON.stringify(body), 'basicNewBackend', "json", 'json', {}, 0) as EntityFormatos[];
            result = result.filter(row => !!row.estado);
            Object.assign(informationDataInitial, { [entity]: { called: true, count: result.length, date: new Date() } });
            await this.dbLocal.insertDataStore({ nameStore: entity, data: result });
        } else {
            // Modo automático
            const fecha: Date = new Date(informationDataInitial[entity].date as string);
            body = { ...body, idFechaInicio: AdapterGenerico.getIdFechaGlobal(fecha) } as any;

            let result = await this.service.call<any>("POST", url, JSON.stringify(body), 'basicNewBackend', "json", 'json', {}, 0) as EntityFormatos[];
            const resultDeleted = result.filter(row => !row.estado);
            const resultUpdated = result.filter(row => !!row.estado);
            Object.assign(informationDataInitial, { [entity]: { called: true, count: 1, date: new Date() } });
            await this.dbLocal.insertDataStore({ nameStore: entity, data: resultUpdated });
            await this.dbLocal.deleteByIndexStore({ nameStore: entity, value: resultDeleted.map(row => row._id) });
        }

    }

    //! Templates
    private async selectEntityREST(entity: keyof EntityInformationDataInitial, informationDataInitial: EntityInformationDataInitial, url: string, body: any, cache: boolean = false, mode: 'Diferencial' | 'Update' | '' = '', method: 'GET' | 'POST' = 'POST', typeAuth: 'basic' | 'basicNewBackend' = 'basic') {
        let data: Array<any> = [];
        try {
            data = await this.service.call<any>(method, url, JSON.stringify(body), typeAuth, "json", 'json', {}, 0);
            data = typeof data === 'string' ? JSON.parse(data) : (Array.isArray(data) ? data : [data]);

            if (mode === 'Diferencial') {} 
            else if (mode === '') 
                Object.assign(informationDataInitial, { [entity]: { called: true, count: data.length, date: new Date() } });
            else
                Object.assign(informationDataInitial, { [entity]: { ...informationDataInitial[entity], date: new Date() } });

            await this.dbLocal.insertDataStore({ nameStore: entity, data });
            return data;
        } catch(err) {
            return [];
        } finally {
            return data
        }
    }

    public async selectTipoOperacion(params: number[]): Promise<EntityTipoOperacion[]> {
        let data: EntityTipoOperacion[] = [];
        try {
            const body = { Filtros: JSON.stringify([{ $match: { IdTipoOperacion: { $in: params } }}, { $project: { Estado: 0, Procesos: 0, _id: 0 } }]) }
            data = await this.service.call<any>("POST", `${this.urlBase}/search/TipoOperacion`, JSON.stringify(body), "basic", "json", 'json', {}, 0);
        } catch(err) {}

        return data;
    }
}