import React, { memo } from 'react';
import { ElementBreadCrumb } from '../../../shared/Components/ElementBreadCrumb';
import Stepper from '../../../shared/Components/ElementStepper';
import ViewDatosGenerales from './FormDynamic/ViewDatosGenerales';
import './Style.scss';

import { EntityLoadQuestion } from '../Domain/EntityLoadQuestion';
import { ViewFormDynamic } from './FormDynamic/ViewFormDynamic';
import { EntityAutochequeoItems } from '../../../shared/Domain/EntityAutochequeoItems';
import { EntityExternalConditionals } from '../Domain/EntityConditionals';
import ElementStepperContent from '../../../shared/Components/ElementStepperContent';
import { EntityAutochequeoTitulos } from '../../../shared/Domain/EntityAutochequeoTitulos';
import { ViewDatosTipos } from './FormDynamic/ViewDatosTipos';
import { EntityTipologiaCP } from '../Domain/EntityTipologiaCP';
import { EntityModuleForm } from '../Domain/EntityModuleForm';
import { EntitySelectPersonal } from '../../../shared/Domain/EntityPersonal';
import { EntityDataForm, EntityLoadData, EntityLoadDataGuest } from '../Domain/EntityUtils';

interface Props {
  onChangeContentForTab: (newKeyTab: number) => Promise<any>;
  formRegistro: {
    values: EntityDataForm;
    touched: any;
    errors: any;
    handleBlur: Function;
  };
  selectInformation: EntityLoadData;
  selectInformationGuest: EntityLoadDataGuest;
  loading: boolean;
  keyTab: number;
  onChange: (name: any, value: any) => void;
  autoCompletePersonal: () => Promise<EntitySelectPersonal[]>;
  openModalComentarios: Function;
  openModalListaComentarios: Function;
  question: EntityLoadQuestion;
  listQuestionShow: EntityAutochequeoItems[];
  titleQuestionGroupToDisplay: EntityAutochequeoTitulos;
  conditionals: EntityExternalConditionals[];
  tipologiaCP: EntityTipologiaCP[];
  moduleForm: EntityModuleForm;
  navbarModule: {
    navigate: boolean;
    path: string;
    text: string;
  }[]
}

const View = (props: Props) => {
  const OT = props.formRegistro.values["OT"];
  const isLastStep = (props.moduleForm.key === 'CCP' || props.question.AutochequeoTitulos.length === props.keyTab);
  const paisSelected = OT?.dataComplete.Pais || props.formRegistro.values.Pais?.dataComplete || { IdPais: -1, Pais: '' };

  return (
    <div className="ViewAutochequeoForm">
      <div className="container-fluid">
        <div className="row mb-2 mt-2">
          <ElementBreadCrumb list={props.navbarModule} />
        </div>

        {/* Stepper 1 */}
        <ViewDatosTipos
          keyTab={props.keyTab}
          onChangeContentForTab={props.onChangeContentForTab}
          selectInformation={props.selectInformation}
          formRegistro={props.formRegistro}
          onChange={props.onChange}
          loading={props.loading}
          tipologiaCP={props.tipologiaCP}
        />

        {/* Stepper 2 */}
        <ViewDatosGenerales
          keyTab={props.keyTab}
          onChangeContentForTab={props.onChangeContentForTab}
          selectInformation={props.selectInformation}
          selectInformationGuest={props.selectInformationGuest}
          formRegistro={props.formRegistro}
          onChange={props.onChange}
          loading={props.loading}
          paisSelected={paisSelected}
          isCP={props.moduleForm.key === 'CP'}
          isModeGuest={props.moduleForm.key === 'CPTGuest'}
        />

        {/* content question */}
        {
          (props.keyTab > 0 || (['PREVIEW', 'CCP'].includes(props.moduleForm.key) && !props.loading)) &&
          <>
            <Stepper
              data={props.question.AutochequeoTitulos.map(tlo => tlo.Titulo)}
              className='px-3 StepperCustomStyle'
              onChange={props.onChangeContentForTab}
              stepPosition={props.keyTab}
              ocult={props.moduleForm.key === 'CCP'}
            />

            <ElementStepperContent
              step={props.keyTab}
              currentStep={props.keyTab}
              title={props.titleQuestionGroupToDisplay?.Titulo || ''}
              removeButtonSubmit={(props.moduleForm.key === 'PREVIEW' && isLastStep)}
              onSubmit={props.onChangeContentForTab}
              isLastStep={isLastStep}
              >
              <ViewFormDynamic
                listQuestion={props.listQuestionShow}
                onChange={props.onChange}
                formRegistro={props.formRegistro}
                conditionals={props.conditionals}
                groupTitle={props.titleQuestionGroupToDisplay}
                isModePreview={props.moduleForm.key === 'PREVIEW'}
                isModeCCP={props.moduleForm.key === 'CCP'}
                idGroup={props.keyTab}
                autoCompletePersonal={props.autoCompletePersonal}
                selectInformation={{
                  Personal: (paisSelected.IdPais === -1) ?
                    [] 
                  : (
                      props.moduleForm.key === 'CPTGuest' ?
                      props.selectInformationGuest.PersonalLite.filter(rsl => rsl.dataComplete.IdPais === paisSelected.IdPais)
                      :
                      props.selectInformation.Personal.filter((rsl) => rsl.dataComplete.IdPais === paisSelected.IdPais)
                  ),
                  EPCS: props.selectInformation.EPCS,
                  MedidasPreventivas: props.selectInformation.MedidasPreventivas,
                  TipoRiesgo: props.selectInformation.TipoRiesgo,
                  OpcionAudio: props.selectInformation.OpcionAudio
                }}
              />
            </ElementStepperContent>
          </>
        }

        {
          ['PREVIEW'].includes(props.moduleForm.key) ? // && props.formRegistro.values.IdMomento !== 2)
            <>
              <div className="position-fixed float-custom-button" onClick={() => props.openModalListaComentarios()}>
                <i className="fa-sharp fa-solid fa-star" style={{ fontSize: 20 }}></i>
              </div>
            </>
          : null
        }

      </div>
    </div>
  );
};

export default memo(View);