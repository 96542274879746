import React from 'react';
import { EntityAutochequeoItems, EntityAutochequeoItemsDisponibilidad } from '../../../../shared/Domain/EntityAutochequeoItems';
import { EntityAutochequeoTitulos } from '../../../../shared/Domain/EntityAutochequeoTitulos';
import { EntitySelectPersonal } from '../../../../shared/Domain/EntityPersonal';
import { LanguageTranslate } from '../../../../shared/Infraestructure/LanguageTranslate';
import { EntityExternalConditionals } from '../../Domain/EntityConditionals';
import { ComponentAudio, ComponentEstadoEquipo, ComponentFirma, ComponentImage, ComponentListPersonalCheck, ComponentObservacion, ComponentPersonal, ComponentRiesgos } from './Inputs';
import { InputSelect, InputText } from '../../../../shared/Components/ElementInputsCostume';
import { EntityMedidaPreventivas } from '../../../../shared/Domain/Catalogos/EntityMedidaPreventivas';
import { EntityTipoRiesgo } from '../../../../shared/Domain/Catalogos/EntityTipoRiesgo';
import { EntityEPCS } from '../../../../shared/Domain/Catalogos/EntityEPCS';
import { EntityOpcionAudio } from '../../../../shared/Domain/Catalogos/EntityOpcionAudio';
import { EntitySelectBase } from '../../../../shared/Domain/EntitySelectBase';
import { AdapterConfigure } from '../../Infaestructure/AdapterConfigure';
import { EntityLitePersonal } from '../../../../shared/Domain/CatalogosLite/EntityLitePersonal';

interface IProps {
    listQuestion: EntityAutochequeoItems[];
    onChange: (name: string, value: any) => void;
    formRegistro: { values: any; };
    groupTitle: EntityAutochequeoTitulos;
    conditionals: EntityExternalConditionals[];
    idGroup: number;
    selectInformation:{
        Personal: EntitySelectPersonal[] | EntitySelectBase<EntityLitePersonal>[];
        EPCS: { label: string; value: any; dataComplete: EntityEPCS }[];
        MedidasPreventivas: { label: string; value: any; dataComplete: EntityMedidaPreventivas }[];
        TipoRiesgo: { label: string; value: any; dataComplete: EntityTipoRiesgo }[];
        OpcionAudio: { label: string; value: any; dataComplete: EntityOpcionAudio }[];
    };
    isModePreview: boolean;
    isModeCCP: boolean;
    autoCompletePersonal: () => Promise<EntitySelectPersonal[]>;
}

export const ViewFormDynamic = (props: IProps) => {
    const languageTranslate = LanguageTranslate();
    const { formRegistro, onChange, conditionals, idGroup, selectInformation, listQuestion, groupTitle } = props;
    const hasRequirements = Object.values(groupTitle?.Requerimientos || []).some(item => item === 1);
    const customOnChangeTitle = (key: string, value: any) => onChange('grupoTitulos', {data: value, key, idGroup})
    const currentValueTitle = (formRegistro.values.grupoTitulos[idGroup] || {});
    let numberQuest = 0;

    return (
        <>
            {
                listQuestion.map((data, index) => {
                    const currentValue = (formRegistro.values.grupoPreguntas[idGroup] || {})[data.IdRegistro] || {};
                    const idShowAwnser = 1;
                
                    if (data.VisibilidadPregunta !== idShowAwnser) {
                        const conditionFinded = conditionals.find(item => item.idHijo === data.IdRegistro);
                        if(!conditionFinded) return <></>;
                        const valueConditioned = (formRegistro.values.grupoPreguntas[idGroup] || {})[conditionFinded.idParent]?.respuesta;
                        if (conditionFinded.valueMatch !== valueConditioned) return <></>;
                    };

                    numberQuest += 1;
                    const arrInputs: EntityAutochequeoItemsDisponibilidad[] = JSON.parse(data.Disponibilidad);
                    const arrInputsEstadoEquipos: EntityAutochequeoItemsDisponibilidad[] = JSON.parse(data.Estados);
                    const valueSelected = arrInputs.find(item => (item.Respuesta || item.Opcion) === currentValue?.respuesta);
                    const valueSelectedEstadoEquipos = arrInputsEstadoEquipos.find(item => item.Respuesta === currentValue?.estadoEquipo);
                    const customOnChange = (key: string, value: any) => onChange('grupoPreguntas', {data: value, dataParent: data, key, idGroup})

                    const isLargeList = arrInputs.length > 3;
                    return (
                        <div className='mb-4' key={data._id}>
                            <p>{numberQuest}) { data.Item }</p>
                            {data.Help && <p className='text-muted mb-3'> <em dangerouslySetInnerHTML={{ __html: data.Help }} /> </p>}
                            <div className={`d-flex ${isLargeList ? 'flex-column' : ''}`}>
                                {
                                    arrInputs.map(item => {
                                        const label: string = (item.Respuesta || item.Opcion) || '';
                                        const isCheked = (label === currentValue.respuesta) || false;
                                        const translated: any = {
                                            SI: languageTranslate.btnSi,
                                            NO: languageTranslate.btnNo,
                                            NA: 'NA'
                                        }

                                        return (
                                            <div className={`mx-2 ${isLargeList ? 'my-1' : ''}`} key={label}>
                                                <label> {translated[label] || label} </label>
                                                <input
                                                    type="radio"
                                                    name={data._id + idGroup.toString() + label}
                                                    value={label}
                                                    checked={isCheked}
                                                    disabled={data.Disabled || props.isModePreview}
                                                    className='radio_custom mx-1'
                                                    onChange={(evt) => customOnChange('respuesta', evt.target.value)}
                                                />
                                            </div>
                                        )
                                    })
                                }
                            </div>

                            {
                                data?.TypeInput === AdapterConfigure.KEYS_FORM.specialInputs.riesgos ? 
                                    <ComponentRiesgos
                                        onChange={customOnChange}
                                        value={currentValue}
                                        options={props.selectInformation.TipoRiesgo}
                                        disabled={props.isModePreview}
                                    />
                                : null
                            }

                            {
                                [AdapterConfigure.KEYS_FORM.specialInputs.medidaspreventivas].includes(data?.TypeInput || '') ?
                                    <>
                                        <InputSelect
                                            label=''
                                            name={'medidasPreventivas'}
                                            onChange={customOnChange}
                                            values={currentValue}
                                            options={props.selectInformation.MedidasPreventivas.filter(row => 
                                                AdapterConfigure.KEYS_FORM.specialInputs.listOpcionDefaultMedidasPreventivas.includes(row.dataComplete?.opcionPregunta?.codigo) ||
                                                (props.formRegistro.values.Riesgos || []).find((_row: any) => row.dataComplete.tipoRiesgo.map(__row => __row.codigo).includes(_row.dataComplete.codigo))
                                            )}
                                            disabled={props.isModePreview}
                                            isMulti
                                            closeMenuOnSelect
                                            controlShouldRenderValue
                                            disabledVirtualized
                                        />
                                        {
                                            (currentValue.medidasPreventivas || []).find((row: EntitySelectBase<EntityMedidaPreventivas>) => [AdapterConfigure.KEYS_FORM.specialInputs.indicarOtroMedidasPreventivas].includes(row.dataComplete?.opcionPregunta?.codigo)) ? 
                                            <InputText
                                                label=''
                                                name='otroMedidasPreventivas'
                                                values={currentValue}
                                                onChange={customOnChange}
                                                disabled={props.isModePreview}
                                            />
                                            : null
                                        }
                                    </>
                                : null
                            }

                            {
                                [AdapterConfigure.KEYS_FORM.specialInputs.epcs].includes(data?.TypeInput || '') ?
                                <>
                                    <InputSelect
                                        label=''
                                        name={'epcs'}
                                        onChange={customOnChange}
                                        values={currentValue}
                                        options={props.selectInformation.EPCS.filter(row =>
                                            AdapterConfigure.KEYS_FORM.specialInputs.listOpcionDefaultEPCS.includes(row.dataComplete?.opcionPregunta?.codigo) ||
                                            (props.formRegistro.values.Riesgos || []).find((_row: any) => row.dataComplete.tipoRiesgo.map(__row => __row.codigo).includes(_row.dataComplete.codigo))
                                        )}
                                        disabled={props.isModePreview}
                                        isMulti
                                        closeMenuOnSelect
                                        controlShouldRenderValue
                                        disabledVirtualized
                                    />
                                    {
                                        (currentValue.epcs || []).find((row: EntitySelectBase<EntityEPCS>) => [AdapterConfigure.KEYS_FORM.specialInputs.indicarOtroEpcs].includes(row.dataComplete?.opcionPregunta?.codigo)) ? 
                                        <InputText
                                            label=''
                                            name='otroEPCS'
                                            values={currentValue}
                                            onChange={customOnChange}
                                            disabled={props.isModePreview}
                                        />
                                        : null
                                    }
                                </>
                                : null
                            }

                            { (data.TypeInput === AdapterConfigure.KEYS_FORM.specialInputs.firmaJefeObra || valueSelected?.TypeInput === AdapterConfigure.KEYS_FORM.specialInputs.firmaJefeObra) ? <ComponentFirma onChange={customOnChange} value={currentValue} disabled={props.isModePreview} /> : null }
                            {
                                data?.TypeInput === AdapterConfigure.KEYS_FORM.specialInputs.audio ?
                                    <ComponentAudio
                                        onChange={customOnChange}
                                        value={{ ...currentValue, EPCS: props.formRegistro.values.EPCS || [], MedidasPreventivas: props.formRegistro.values.MedidasPreventivas || [], Riesgos: props.formRegistro.values.Riesgos || [], otroRiesgo: props.formRegistro.values.otroRiesgo, otroEPCS: props.formRegistro.values.otroEPCS, otroMedidasPreventivas: props.formRegistro.values.otroMedidasPreventivas }}
                                        disabled={!!props.isModePreview}
                                        opcionAudio={props.selectInformation.OpcionAudio}
                                    />
                                    :
                                    null
                            }
                            { (data?.TypeInput === AdapterConfigure.KEYS_FORM.specialInputs.personal || valueSelected?.TypeInput === AdapterConfigure.KEYS_FORM.specialInputs.personal) ? <ComponentPersonal onChange={customOnChange} value={currentValue} options={selectInformation.Personal} disabled={props.isModePreview} autoCompletePersonal={props.autoCompletePersonal} /> : null }
                            {valueSelected && valueSelected?.TypeInput === AdapterConfigure.KEYS_FORM.specialInputs.personalPreventivo && <ComponentListPersonalCheck onChange={customOnChange} value={{ Personal: props.formRegistro.values.Personal || [], ...currentValue }} options={[]} disabled={props.isModePreview} autoCompletePersonal={props.autoCompletePersonal} />}

                            {valueSelected && valueSelected.Estados === 1 && <ComponentEstadoEquipo onChange={customOnChange} value={currentValue.estadoEquipo} data={data} estados={arrInputsEstadoEquipos} idGroup={idGroup} disabled={props.isModePreview} />}
                            {valueSelected && (valueSelected.Observacion === 1 || valueSelectedEstadoEquipos?.Observacion === 1) && <ComponentObservacion onChange={customOnChange} value={currentValue.observacion} disabled={props.isModePreview} isRequerid />}
                            {/* valueSelected && (valueSelected.CharlaPreTarea === 1 || valueSelectedEstadoEquipos?.CharlaPreTarea === 1) && !props.isModePreview && <ComponentAudio onChange={customOnChange} value={currentValue.charlaPreTarea} disabled={props.isModePreview} /> */}
                            {/* valueSelected && (valueSelected?.Personal === 1 || valueSelectedEstadoEquipos?.Personal === 1) && <ComponentPersonal onChange={customOnChange} value={currentValue} options={selectInformation.Personal} disabled={props.isModePreview} autoCompletePersonal={props.autoCompletePersonal} /> */}
                            {
                                valueSelected && (valueSelected.CantidadFotos > 0 || (valueSelectedEstadoEquipos?.CantidadFotos || 0) > 0 || (valueSelected.Foto || 0)  > 0) &&
                                <ComponentImage
                                    onChange={customOnChange}
                                    value={currentValue.images}
                                    className='mt-3'
                                    disabled={props.isModePreview}
                                    countRequiredPhotos={valueSelected.CantidadFotos || valueSelectedEstadoEquipos?.CantidadFotos || valueSelected.Foto || 0}
                                    showRequiredPhotos
                                />
                            }

                            {
                                (index + 1) !== listQuestion.length ?
                                    <div className='my-3 border' /> 
                                    :
                                    null
                            }
                        </div>
                    )
                })
            }
            {
                hasRequirements && (
                    groupTitle.Requerimientos.Observacion || groupTitle.Requerimientos.Fotografia ? (
                        <div className='shadow p-3 mt-2 mb-4 rounded'>
                            {/*
                                props.isModeCCP ? null :
                                <>
                                    <label>{languageTranslate.moduloAutoChequeo.form.inputs.tituloGrupo}</label>
                                    <div className='my-3 border' />
                                </>
                            */}
                            { groupTitle.Requerimientos.Observacion === 1 && <ComponentObservacion onChange={customOnChangeTitle} value={currentValueTitle.observacion} disabled={props.isModePreview} isRequerid /> }
                            { groupTitle.Requerimientos.Fotografia === 1 && <ComponentImage showRequiredPhotos={false} onChange={customOnChangeTitle} value={currentValueTitle.images} disabled={props.isModePreview} isRequerid /> }
                        </div>
                    ) : (
                        <div className=''>
                            { groupTitle.Requerimientos.ObservacionCPT === 1 && <ComponentObservacion onChange={customOnChangeTitle} value={currentValueTitle.observacion} disabled={props.isModePreview} /> }
                            { groupTitle.Requerimientos.FotografiaCPT === 1 && <ComponentImage showRequiredPhotos={false} onChange={customOnChangeTitle} value={currentValueTitle.images} disabled={props.isModePreview} /> }
                        </div>
                    )
                )
            }

        </>
    )
}